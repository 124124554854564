import { Component, OnInit } from '@angular/core';
import { OpentokService } from 'src/app/services/videochat/OpenTok/opentok.service';
import { SignalingServiceModule } from 'src/app/services/videochat/signaling-service/signaling-service.module';

@Component({
  selector: 'app-videocall-modal-mobile',
  templateUrl: './videocall-modal-mobile.component.html',
  styleUrls: ['./videocall-modal-mobile.component.scss'],
})
export class VideocallModalMobileComponent implements OnInit {

  hideModal = true;
  dragging = false;
  modalWidth = '100%';
  modalHeight = 'fit-content';
  reducedMode = false;
  isLoading = true;

  dragPosition = { x: 0, y: 0 };

  constructor(
    private opentokService: OpentokService,
    private signal: SignalingServiceModule,
  ) { }

  ngOnInit(): void {
    console.log('VideocallModalMobileComponent');
    this.opentokService.subscribeStartConference()
      .subscribe((started) => {
        this.hideModal = !started;
        this.reducedMode = false;
      });

    this.opentokService.subscribeStreamCreated()
      .subscribe((stream) => {
        console.log('Remote stream subscribed');
        this.dragPosition = { x: this.dragPosition.x, y: this.dragPosition.y + 1 };
      });
  }

  hangUp() {
    this.opentokService.hangUP();
    this.signal.sendhangUpCall();
  }

  startDrag() {
    this.dragging = true;
  }

  endDrag(evt: unknown) {
    setTimeout(() => {
      this.dragging = false;
    }, 100);
  }

  toggleReducedMode(): void {
    if (this.reducedMode) {
      this.modalWidth = '100%';
      this.modalHeight = 'fit-content';
      this.reducedMode = false;
      this.dragPosition = { x: 0, y: 0 };
      return;
    }
    this.modalWidth = '80px';
    this.modalHeight = '65px';
    this.reducedMode = true;
  }

}
