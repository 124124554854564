import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class UiHelperService {
    constructor(private toastController: ToastController) { }

    async showToast(message: string, type?: 'error' | 'success' | 'warning') {
        const toast = await this.toastController.create({
            message,
            color: type || 'primary',
            duration: 5000,
        });
        toast.present();
    }

    public generateRandomColor(colorsToGen: number): Array<string> {
        const randomColorsList = [];
        for (let i = 0; i < colorsToGen; i++) {
            // this.randomColorsList.push(('#' + Math.floor(Math.random() * 16777215).toString(16)));
            const lum = -0.25;
            let hex = String(
                '#' + Math.random().toString(16).slice(2, 8).toUpperCase()
            ).replace(/[^0-9a-f]/gi, '');
            if (hex.length < 6) {
                hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
            }
            let rgb = '#', c: any, y: any;
            for (y = 0; y < 3; y++) {
                c = parseInt(hex.substr(y * 2, 2), 16);
                c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
                rgb += ('00' + c).substr(c.length);
            }
            randomColorsList.push(rgb);
        }
        return randomColorsList;
    }
}
