export class XDotCharacteristicsHelper {

    static services = {
        measuramentService: {
            uuid: '15172000-4947-11E9-8646-D663BD873D93',
            characteristics: {
                controlUuid: '15172001-4947-11E9-8646-D663BD873D93',               // control
                mediumPayloadLengthUuid: '15172003-4947-11E9-8646-D663BD873D93',   // Notify
                shortPayloadLengthUuid: '15172004-4947-11E9-8646-D663BD873D93',    // Notify
            }
        }

    };
}