import {UiConstants} from '../app/constants/ui/ui-constants';

export const environment = {
  production: true,
};

export const OPENTOKAPIKEY = '46668032';



export const BASE_SERVER_DEBUG = 'https://medicoamicopro.com';
export let BASE_SERVER =  'https://medicoamicopro.com'; // 'http://localhost:3000'; //localStorage.getItem(UiConstants.SERVERURL); // 'https://medico-amico.com';

export let BASE_URL = `${BASE_SERVER}/api/v1`;
export let SOCKETIO_URL = BASE_SERVER;

//PRODUZIONE
export const COGNITO_CLIENT_ID = '1vidap4abu98fi5g9igi32qf1n';
export const ONESIGNAL_APP_ID = '705448ae-20d9-4f30-b4dd-73cfca9aef2e';


//STAGING
//export const COGNITO_CLIENT_ID = '7jpjcv8udnmq104jq6qskl9b1b';
//export const ONESIGNAL_APP_ID = '1aa35605-2151-4aaf-a9e4-19df8a715e2b';



