<div class="incoming-exercise-dialog">
  <p>{{ 'remote_exercises.incoming_exercise' | translate }}</p>
  <div class="btn-holder">
    <ion-button [fill]="'clear'" (click)="accept()">
      {{ 'yes' | translate }}
    </ion-button>
    <ion-button [fill]="'clear'" (click)="refuse()">
      {{ 'no' | translate }}
    </ion-button>
  </div>
</div>