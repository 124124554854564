export class UiConstants {
  static EMAIL_REGEX =
    "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?";
  static PASSWORD_REGEX = '((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W]).{8,64})';
  static DATE_REGEX = /^\d{1,2}\.\d{1,2}\.\d{4}$/;
  static VAT_NUMBER_REGEX = /^\d{11}$/;
  static PHONE_REGEX =
    '\\+(9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\W*\\d\\W*\\d\\W*\\d\\W*\\d\\W*\\d\\W*\\d\\W*\\d\\W*\\d\\W*(\\d{1,2})$';

  static TOKEN_STORAGE_KEY = 'auth-token';
  static ID_TOKEN_STORAGE_KEY = 'id-token';
  static REFRESH_TOKEN_STORAGE_KEY = 'refresh-token';
  static TOKEN_EXPIRATION = 'token-expiration';
  static USER_ROLE_STORAGE_KEY = 'role';
  static USER_ID_STORAGE_KEY = 'user-id';
  static SERVERURL = 'server-ulr';

  static EXERCISE_PRESCRIPTION = 'auto_exercises';
  static PROTOCOL_PRESCRIPTION = 'exercises';
  static MEDICINE_PRESCRIPTION = 'medicines';
  static DETECTION_PRESCRIPTION = 'measurements';

  static DOCTORS_TYPE = [
    'family_medicine_doctor',
    'physiotherapist',
    'physiatrist',
    'orthopedic',
    'pediatrician',
    'geriatrician',
    'allergist',
    'dermatologist',
    'ophthalmologist',
    'gynecologist',
    'cardiologist',
    'endocrinologist',
    'gastroenterologist',
    'urologist',
    'pulmonologist',
    'neurologist',
    'psychiatrist',
    'psychiatrist',
    'oncologist',
  ];

  static VIDEO_PLAYBACK_SPEED = {
    '1': 0.5,
    '2': 0.75,
    '3': 1,
    '4': 1.25,
    '5': 1.5,
  };
}
