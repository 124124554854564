/// <reference types="web-bluetooth" />
import { Injectable } from "@angular/core";
import { Platform, ToastController } from "@ionic/angular";
import { BehaviorSubject } from "rxjs";
import { BluetoothServiceBase } from "../bluetooth_service_base";
import { BLEDevice, BLEConnectionStatus } from "../../interfaces/ble-device.interface";
import { cloneDeep } from 'lodash';
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class SensorBluetoothServiceWeb extends BluetoothServiceBase {
    private static readonly _TAG: String = '[SBSW]';
    private get bluetooth(): Bluetooth {
        return window.navigator.bluetooth;
    }

    bleDevices: BLEDevice[] = [];
    isScanning: boolean = false;

    removeDevicesAndListeners: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(platform: Platform) {
        super(platform);
    }

    async initService(): Promise<void> {
        console.log(`${SensorBluetoothServiceWeb._TAG} Started InitService`);
        const init = await this.platform.ready();
        this.initSavedDevices();
        // this.bleDevices = [...this.getSavedDevices()];
        new Promise(resolve => setTimeout(resolve, 2000));
        console.log(`${SensorBluetoothServiceWeb._TAG} Platform ready`);
        console.log(`${SensorBluetoothServiceWeb._TAG} Initialize finished with status:`, init);
    }

    public async startScan(): Promise<void> {
        this.removeDevicesAndListeners.next(false);
        const measuramentServiceUUID: BluetoothServiceUUID = this.measuramentServiceUUID.toLowerCase();
        let options: RequestDeviceOptions = {
            filters:
            [
                {name: 'Xsens DOT'},
                {name: 'Movella DOT'}
            ],
            optionalServices: [measuramentServiceUUID]
        };
        try {
            this.isScanning = true;
            let device: BluetoothDevice = await this.bluetooth.requestDevice(options);
            this.isScanning = false;
            let tmpDevice: BLEDevice = {
                name: device.name,
                id: device.id,
                selected: false,
                observables: {},
                status: BLEConnectionStatus.connecting
            };

            this.addDevice(tmpDevice, true);
            console.log(this.bleDevices);
            console.log(`${SensorBluetoothServiceWeb._TAG} connecting to ${device.name} [${device.id}]..`);
            let _isDeviceAlreadyConnected: boolean = this.isDeviceAlreadyConnected(device.id);
            if (_isDeviceAlreadyConnected) {
                console.log(`${SensorBluetoothServiceWeb._TAG} device ${device.name} [${device.id}] is alredy connected, skip connection`);
                return;
            }


            device.gatt.connect()
  .then(async (server: BluetoothRemoteGATTServer) => {
    console.log(`${SensorBluetoothServiceWeb._TAG} device ${device.name} [${device.id}] connected`);

    // Assicurati che la connessione sia stabile prima di procedere
    if (device.gatt.connected) {
      try {
        tmpDevice.status = BLEConnectionStatus.connected;
        tmpDevice.selected = true;
        this.updateDevice(tmpDevice, true);

        device.ongattserverdisconnected = (e) => this.onDeviceDisconnected(e, device.id);
        const controlCharacteristicUuid: BluetoothServiceUUID = this.controlCharacteristicUuid.toLowerCase();
        const shortLongPayloadcUuid: BluetoothServiceUUID = this.shortLongPayloadcUuid.toLowerCase();
        let measurementService: BluetoothRemoteGATTService = await server.getPrimaryService(measuramentServiceUUID);
        let controlCharacteristic: BluetoothRemoteGATTCharacteristic = await measurementService.getCharacteristic(controlCharacteristicUuid);
        let arrayBufferStartMeasuramentCommand: ArrayBuffer = this._typedArrayToBuffer(new Uint8Array([1, 1, 5]));

        await controlCharacteristic.writeValue(arrayBufferStartMeasuramentCommand);

        let shortPayloadCharatteristic: BluetoothRemoteGATTCharacteristic = await measurementService.getCharacteristic(shortLongPayloadcUuid);
        this.handleShortPayloadChatatteristic(shortPayloadCharatteristic, tmpDevice, device);
      } catch (e) {
        this.isScanning = false;
        console.error(`${SensorBluetoothServiceWeb._TAG} has catched an error during discovering services `, e);
        // Gestisci l'errore qui, ad esempio, chiudi la connessione o esegui altre azioni necessarie
      }
    } else {
      console.error(`${SensorBluetoothServiceWeb._TAG} GATT Server is disconnected.`);
    }
  })
  .catch((e) => {
    tmpDevice.status = BLEConnectionStatus.disconnected;
    tmpDevice.selected = false;
    this.updateDevice(tmpDevice, true);
    console.error(`${SensorBluetoothServiceWeb._TAG} has catched an error during connection services `, e);
  });

        } catch (e) {
            this.isScanning = false;
            console.error(`${SensorBluetoothServiceWeb._TAG} has catched an error during requesting devices `, e);
        }


    }

    async handleShortPayloadChatatteristic(characteristic: BluetoothRemoteGATTCharacteristic, currentConnectedDevice: BLEDevice, realDevice) {
        console.log(`${SensorBluetoothServiceWeb._TAG} starting notification for characteristic`, characteristic.uuid);
        let char: BluetoothRemoteGATTCharacteristic = await characteristic.startNotifications();
        currentConnectedDevice.observables = { controlSensorValue: new BehaviorSubject<Uint8Array>(new Uint8Array()) };
        this.updateDevice(currentConnectedDevice, false);
        characteristic.addEventListener('characteristicvaluechanged', (e) => {
            let target: any = e.target;
            let uint8ArrayData: Uint8Array = new Uint8Array(target.value.buffer);
            currentConnectedDevice.observables.controlSensorValue.next(uint8ArrayData);
        });

        this.removeDevicesAndListeners.subscribe((data) => {
            if (data) {
                characteristic.removeEventListener('characteristicvaluechanged', () => {
                    console.log(`${SensorBluetoothServiceWeb._TAG} removed listener characteristicvaluechanged`);
                });
                realDevice.gatt.disconnect();
                this.removeDevice(currentConnectedDevice.id);
            }
        });
    }

    onDeviceDisconnected(event: Event, deviceId: String) {
        let device: BLEDevice = this.getDeviceFromId(deviceId);
        if (device != null) {
            device.status = BLEConnectionStatus.disconnected;
            if (device.observables.controlSensorValue != null) {
                device.observables.controlSensorValue.unsubscribe();
                device.observables = null;
                device.selected = false;
            }
            this.updateDevice(device, true);
        }

        console.log(`${SensorBluetoothServiceWeb._TAG} On Device disconnected`, event);
    }

    selectDevice(id): void {
        let device: BLEDevice = this.getDeviceFromId(id);
        device.selected = true;
        this.updateDevice(device, true);

    }
    unselectDevice(id): void {
        let device: BLEDevice = this.getDeviceFromId(id);
        device.selected = false;
        this.updateDevice(device, true);
    }


    dispose(): void {
        if (this.bleDevices) {
            this.removeDevicesAndListeners.next(true);
            localStorage.removeItem('chosenDevices');
        }
    }


    disconnectSpecificSensor(address: string): Promise<void> {
        throw new Error("Method not implemented.");
    }

    async connect(deviceId): Promise<boolean> {
        // Study a better approach to connect to a device in separate way not in scan function
        let device: BLEDevice = this.getDeviceFromId(deviceId);
        if (device != null) {
            if (device.status === BLEConnectionStatus.connected) {
                return true;
            }
        }
        await this.startScan();
        return true;

    }

    async stopScan(): Promise<void> {
        // This method is not available on web side
        return;
        //throw new Error("Method not implemented.");
    }


    private _typedArrayToBuffer(array: Uint8Array): ArrayBuffer {
        return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
    }
}