<div visible="false" (cdkDragStarted)="startDrag()" (cdkDragEnded)="endDrag($event)" id="dragWindow" class="example-box"
  [style.height]="subscriberHeight" [style.width]="subscriberWidth" [hidden]="hideVideoCtrl"
  cdkDragBoundary=".drag-boundary" cdkDrag [cdkDragFreeDragPosition]="dragPosition">
  <ion-button [hidden]="!viewVideoIcon" (click)="maximizeVideo()" [size]="'large'" [fill]="'clear'">
    <ion-icon src="./assets/icon/icon_viewcall.svg"></ion-icon>
  </ion-button>
  <ion-grid [hidden]="viewVideoIcon">
    <ion-row>
      <div class="subscriberbox" webkit-playsinline [style.height]="subscriberHeight" [style.width]="subscriberWidth"
        id="subscriber">
      </div>
      <div class="publisherbox" webkit-playsinline [style.height]="publisherHeight" [style.width]="publisherWidth"
        [hidden]="!publisherView" id="publisher">
      </div>
    </ion-row>
    <ion-row class="controls-floating-bottom">
      <div class="controls">

        <ion-col>
          <ion-button (click)="reduceSizeVideo()" [hidden]="!fullscreen" [size]="'large'" [fill]="'clear'">
            <ion-icon src="./assets/icon/icon_fullscreen-exit.svg"></ion-icon>
          </ion-button>
          <ion-button (click)="fullSizeVideo()" [hidden]="fullscreen" [size]="'large'" [fill]="'clear'">
            <ion-icon src="./assets/icon/icon_fullscreen.svg"></ion-icon>
          </ion-button>
        </ion-col>
        <ion-col>
          <ion-button (click)="hangUp()" [size]="'large'" [fill]="'clear'">
            <ion-icon src="./assets/icon/icon_hangupcall.svg"></ion-icon>
          </ion-button>
        </ion-col>
      </div>
    </ion-row>
  </ion-grid>
</div>