import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CreateResponse } from 'src/app/models/chat/CreateResponse';
import { BasePaginateResponse } from 'src/app/models/chat/BasePaginateResponse';
import { ChatMessage } from 'src/app/models/chat/ChatMessage';
import { ChatMessageCreate } from 'src/app/models/chat/ChatMessageCreate';
import { BASE_URL } from 'src/environments/environment';
import { RepositoryHelper } from 'src/app/utils/repository/repository-helper';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatRepositoryService {

    constructor(private http: HttpClient) { }

    createMessage(message: ChatMessageCreate): Observable<CreateResponse> {
        return this.http.post<CreateResponse>(
            `${BASE_URL}${RepositoryHelper.CREATE_MESSAGE}`,
            message,
            { headers: RepositoryHelper.getHeader() }
        );
    }

    listUnreadMessages(page: number = 1): Observable<BasePaginateResponse<{ _id: string }>> {
        return this.http.get<BasePaginateResponse<{ _id: string }>>(
            `${BASE_URL}${RepositoryHelper.USER_NOTIFICATION}`,
            {
                headers: RepositoryHelper.getHeader(),
                params: new HttpParams().set('page', page.toString()),
            }
        );
    }

    getMessages(page: number = 1, recipient: string): Observable<BasePaginateResponse<ChatMessage>> {
        return this.http.get(
            `${BASE_URL}${RepositoryHelper.MESSAGES}`,
            {
                headers: RepositoryHelper.getHeader(),
                params: new HttpParams()
                    .set('page', page.toString())
                    .set('from', recipient),
            }
        ).pipe(
            map((res: BasePaginateResponse<ChatMessage>) => {
                res.data.map(e => {
                    e.createdAt = new Date(e.createdAt);
                    return e;
                });
                return res;
            })
        );
    }

    deleteMessage(messageId: string): Observable<CreateResponse> {
        const options = {
            headers: RepositoryHelper.getHeader(),
            body: {
                id: messageId
            },
        };
        return this.http.delete<CreateResponse>(
            `${BASE_URL}${RepositoryHelper.DELETE_MESSAGE}`,
            options
        );
    }
}
