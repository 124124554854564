import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { User } from '../../models/user/user';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BASE_URL } from '../../../environments/environment';
import { RepositoryHelper } from '../../utils/repository/repository-helper';
import { map } from 'rxjs/operators';
import { Version } from '../../models/version/version';

@Injectable({
  providedIn: 'root',
})
export class UserRepositoryService {
  user: any
  constructor(private httpClient: HttpClient) { }

  public getUser(): Observable<User> {
    try {
      return this.httpClient
        .get<User>(BASE_URL + RepositoryHelper.USER, {
          headers: RepositoryHelper.getHeader(),
        })
        .pipe(map((user) => new User(user)));
    } catch (e) {
      console.log('Error catched in User Repository.getUser: ', e);
      return null;
    }
  }

  public getVersionUpdate(): Observable<Version[]> {
    try {
      return this.httpClient
        .get<Version[]>(BASE_URL + RepositoryHelper.GET_APP_VERSION, {
          headers: RepositoryHelper.getHeader(),
        });
      // .pipe(map((appVersion) => new Version(appVersion)));
    } catch (e) {
      console.log('Error catched in Version update', e);
      return null;
    }
  }
  public getExercisesFilters(): Observable<any> {
    try {
      return this.httpClient.get<void>(BASE_URL + RepositoryHelper.EXERCISES_FILTERS, {
        headers: RepositoryHelper.getHeader(),

      })
    } catch (e) {
      console.log('Error catched : UserRepository.getExercisesFilters', e);
      return;
    }
  }
  public updateUserData(data: object) {
    try {
      return this.httpClient.put(
        BASE_URL + RepositoryHelper.USER_UPDATE,
        {
          data
        },
        {
          headers: RepositoryHelper.getHeader()
        }
      )
    } catch (e) {
      console.log('Error catched in User Repository.updateUserData: ', e);
      return;
    }
  }

  public getStructureGroups(id): Observable<any> {
    try {
      return this.httpClient.get<void>(BASE_URL + RepositoryHelper.STRUCTURE_GROUPS + `?userId=${id}`,
      {headers: RepositoryHelper.getHeader()}
      )
    } catch (error) {

    }
  }

  public updateStructureGroups(structureId: string, groups: string[]) {
    try {
      return this.httpClient.post(
        BASE_URL + RepositoryHelper.STRUCTURE_GROUPS,
        {
          "id": structureId,
          "structureGroups": groups
        },
        { headers: RepositoryHelper.getHeader() },
      )
    } catch (error) {
      console.log('Error catched in : UserRepository.updateStructureGroups', error);
      return
    }
  }
  public updatePassword(newPassword: string): Observable<string> {
    try {
      return this.httpClient.put<string>(
        BASE_URL + RepositoryHelper.USER_UPDATE_PASSWORD,
        {
          password: newPassword,
          confirmPassword: newPassword,
        },
        { headers: RepositoryHelper.getHeader() }
      );
    } catch (e) {
      console.log('Error catched in User Repository.updatePassword: ', e);
      return null;
    }
  } get(structureId: string): Observable<number> {
    try {
      const params = new HttpParams().set('structureId', structureId);
      params.append('structureId', structureId);
      return this.httpClient.get<number>(BASE_URL + RepositoryHelper.NUM_PATIENTS_ACTIVE, {
        headers: RepositoryHelper.getHeader(),
        params,
      });
    } catch (e) {
      console.log('Error catched : UserRepository.getNumPatients', e);
      return;
    }
  }
 
  public getSpecificUser(id): Observable<any> {
    try {
      return this.httpClient.get<any>(BASE_URL + RepositoryHelper.USERS + `/${id}`,
      {headers: RepositoryHelper.getHeader()}
      )
    } catch (error) {

    }
  }
  public deleteUser(userId: string): Observable<void> {
    const options = {
      headers: RepositoryHelper.getHeader(),
      body: {
        id: userId
      }
    };
    return this.httpClient.delete<void>(
      BASE_URL + RepositoryHelper.DELETE_SPECIFIC_USER,
      options
    );
  }

  isUserActive(userId: string): Observable<boolean> {
    try {
      const params = new HttpParams().set('userId', userId);
      return this.httpClient.get<boolean>(BASE_URL + RepositoryHelper.PATIENT_ACTIVE, {
        headers: RepositoryHelper.getHeader(),
        params,
      });
    } catch (e) {
      console.log('Error catched : UserRepository.isUserActive', e);
      return;
    }
  }

  getNumPatients(userId: string): Observable<number> {
    try {
      const params = new HttpParams().set('userId', userId);
      return this.httpClient.get<number>(BASE_URL + RepositoryHelper.NUM_PATIENTS_ACTIVE, {
        headers: RepositoryHelper.getHeader(),
        params,
      });
    } catch (e) {
      console.log('Error catched : UserRepository.getNumPatients', e);
      return;
    }
  }

  getNumActivePatients(userId: string): Observable<number> {
    try {
      const params = new HttpParams().set('userId', userId);
      return this.httpClient.get<number>(BASE_URL + RepositoryHelper.NUM_PATIENTS_ACTIVE, {
        headers: RepositoryHelper.getHeader(),
        params,
      });
    } catch (e) {
      console.log('Error catched : UserRepository.getNumActivePatients', e);
      return;
    }
  }

  getNumMaxActivePatients(userId: string): Observable<number> {
    try {
      const params = new HttpParams().set('userId', userId);
      return this.httpClient.get<number>(BASE_URL + RepositoryHelper.NUM_MAX_PATIENTS_ACTIVE, {
        headers: RepositoryHelper.getHeader(),
        params,
      });
    } catch (e) {
      console.log('Error catched : UserRepository.getNumMaxPatients', e);
      return;
    }
  }

  setNumMaxActivePatients(structureId: string, numMaxActivePatients: number) {
    try {
      const data = {
        structureId: structureId,
        numMaxActivePatients: numMaxActivePatients,
      };
      console.log(data);
      return this.httpClient.post(
        BASE_URL + RepositoryHelper.NUM_MAX_PATIENTS_ACTIVE,
        data,
        { headers: RepositoryHelper.getHeader() },
      );
    } catch (e) {
      console.log('Error catched in : UserRepository.setNumMaxActivePatients', e);
      return;
    }
  }
}
