import { Component, HostListener, OnInit } from '@angular/core';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { DynamicLinkService } from './services/dynamic-links/dynamic-link.service';
import { TranslateConfigService } from './services/language/translate-config.service';
import { AuthService } from './services/auth/auth.service';
import { UserService } from './services/user/user.service';
import { User } from './models/user/user';
import { SignalingServiceModule } from './services/videochat/signaling-service/signaling-service.module';
import { UiConstants } from './constants/ui/ui-constants';
import { BASE_SERVER_DEBUG, ONESIGNAL_APP_ID } from '../environments/environment';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Version } from './models/version/version';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import OneSignal from 'onesignal-cordova-plugin';
import { BLEConnectionStatus, BLEDevice } from './services/sensors/interfaces/ble-device.interface';
import { cloneDeep } from 'lodash';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
    @HostListener('window:unload', ['$event'])
    beforeUnloadHandler(event) {
        console.log(event);
        if (!this.platform.is('cordova')) {
            let devices: BLEDevice[] = JSON.parse(localStorage.getItem('chosenDevices'));
            if (devices && devices.length) {
                const devicesClone = cloneDeep(devices);

                for (let i = 0; i < devicesClone.length; i++) {
                    devices[i].observables = null;
                    devices[i].status = BLEConnectionStatus.disconnected;
                    devices[i].selected = false;
                }
                localStorage.setItem('chosenDevices', JSON.stringify(devices));
            }


        }
    }


    isMobile: boolean;
    isDesktop: boolean;
    isAndroid: boolean;

    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        private navController: NavController,
        private dynamicLinkService: DynamicLinkService,
        private translateConfigService: TranslateConfigService,
        private authService: AuthService,
        private userService: UserService,
        private signal: SignalingServiceModule,
        private androidPermissions: AndroidPermissions,
        private appVersion: AppVersion,
        private alertController: AlertController,
        private translate: TranslateService,
        private route: Router,
        private http: HttpClient
    ) {
        this.initializeApp().then(() => {
        });
    }

    ngOnInit(): void {
    }

    async initializeApp(): Promise<void> {
        await this.platform.ready();
        console.log('initializeApp');
        console.log('Check Platform');
        this.isMobile = this.platform.is('ios') || this.platform.is('android');
        this.isAndroid = this.platform.is('android');
        this.isDesktop = this.platform.is('desktop');

        if (!this.isMobile && !this.isAndroid && !this.isDesktop) {
            this.isDesktop = true;
        }

        console.log(`isDesktop ${this.isDesktop}`);
        console.log(`isMobile ${this.isMobile}`);
        console.log(`isAndroid ${this.isAndroid}`);
        this.splashScreen.hide()
        // check for updates
        this.checkVersion();
        // tslint:disable-next-line:no-string-literal
        if (this.isDesktop || this.isAndroid || (this.isMobile && !window['OT'])) {
            // Loading OpenTok JS SDK
            // tslint:disable-next-line:no-string-literal
            import('../assets/opentok.min').then(ot => window['OT'] = ot.default);
        }

        // se sono in versione desktop altrimenti l'url arriva dal localstorage impostato in fase di registrazione
        if (this.platform.is('desktop')) {
            let url = this.platform.url();

            const localhost = url.indexOf('localhost') > 0;
            if (localhost) {
                url = BASE_SERVER_DEBUG;
            } else {
                let index = url.indexOf('/', 9);  // salto https://
                if (index < 0) {
                    index = url.length;
                }
                url = url.substring(0, index);
            }

            localStorage.setItem(UiConstants.SERVERURL, url);
            console.log('Desktop version: ' + url);
        } else {
            console.log('Mobile version');
        }
        const homeRoutes = [
            '/structure/doctors',
            '/admin/structures',
            '/patient/prescriptions',
            '/doctor/patients',
        ];
        this.platform.backButton.subscribeWithPriority(-1, () => {
            // const url = this.route.url;
            // if (homeRoutes.includes(url)) {
            //     navigator['app'].exitApp();
            // } else {
            //     this.route.navigate(['/']);
            // }
            navigator['app'].exitApp();
        });
        this.setupPermissions();
        this.translateConfigService.setLanguage(this.translateConfigService.getDefaultLanguage());
        this.statusBar.styleDefault();
        this.dynamicLinkService.init();
        this.initOneSignal();
        AuthService.getRefreshedCredentialsFromCognito(this.http).subscribe(
            (authResult) =>{
                console.log(authResult,`questo e authResult`)
                this.splashScreen.hide();
                this.authService.userisAuthenticatedObservable
                .subscribe(async (isAuthenticated) => {
                    console.log('User is Authenticated: ', isAuthenticated);
                    this.splashScreen.hide()
                    try {
                        const user = await this.userService.getUser();
                        // START SIGNALING SERVICE
                        this.signal.startWS(user._id);
                        this.dynamicLinkService.onDynamicLinkOpenObservable
                            .subscribe((isOpeningDynamicLink) => {
                                console.log('User open dynamic link: ', isOpeningDynamicLink);
                                console.log('User : ', user);
                                if (isOpeningDynamicLink) {
                                    console.log('Opening Dynamic Link');
                                }
                                if (user === null) {
                                    this.navController.navigateRoot(['login']);
                                    this.splashScreen.hide();
                                    return;
                                }
                                this.userService.subjectUser.next(user);
                                this.splashScreen.hide();
                                // tslint:disable-next-line:no-string-literal
                                if (this.platform.is('cordova')) {
                                    
                                    // tslint:disable-next-line:no-string-literal
                                    OneSignal.setExternalUserId(user._id);
                                }
                                this.navController.navigateRoot([User.getUserRoleString(user.role)]);
                            },
                                () => this.splashScreen.hide()
                            );
                    } catch (err) {
                        console.error(err),'errore catch';
                        this.splashScreen.hide();
                    }
                });
            },(error)=>{
                console.error(`errore credenziali`, error)
                this.splashScreen.hide();
                
            }
        );         
        
        document.addEventListener("resume", ()=>{
            this.dynamicLinkService.init();
        }, false);

    }

    async initOneSignal() {
        // tslint:disable-next-line:no-string-literal
        this.platform.ready().then((data) => {

            document.addEventListener('deviceready', function () {
                console.log("device-ready", data);
                // NOTE: Update the setAppId value below with your OneSignal AppId.
                OneSignal.setAppId(ONESIGNAL_APP_ID);
                console.log('onesignal');
                OneSignal.setNotificationOpenedHandler(function (jsonData) {
                    console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
                });

                // Prompts the user for notification permissions.
                //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 7) to better communicate to your users what notifications they will get.
                OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
                    console.log("User accepted notifications: " + accepted);
                });

                //     if (!window['plugins'] || !window['plugins'].OneSignal) {
                //         return console.error('can\'t init onesignal');
                //     }
                //     // tslint:disable-next-line:no-string-literal
                //     const oneSignalPlugin = window['plugins'].OneSignal;
                //     // oneSignalPlugin.setLogLevel({ logLevel: 6, visualLevel: 0 });

                //     const notificationOpenedCallback = (jsonData) => {
                //         console.log('notificationOpenedCallback: ' + JSON.stringify(jsonData));
                //     };


                //     // Set your iOS Settings
                //     const iosSettings = {};
                //     // tslint:disable-next-line:no-string-literal
                //     iosSettings['kOSSettingsKeyAutoPrompt'] = false;
                //     // tslint:disable-next-line:no-string-literal
                //     iosSettings['kOSSettingsKeyInAppLaunchURL'] = false;

                //     oneSignalPlugin
                //         .startInit(ONESIGNAL_APP_ID)
                //         .handleNotificationOpened(notificationOpenedCallback)
                //         .iOSSettings(iosSettings)
                //         .inFocusDisplaying(oneSignalPlugin.OSInFocusDisplayOption.Notification)
                //         .endInit();

                //     // The promptForPushNotificationsWithUserResponse function will show the iOS push notification prompt.
                //     // We recommend removing the following code and instead using an In-App Message to prompt for notification permission (See step 6)
                //     oneSignalPlugin.promptForPushNotificationsWithUserResponse(accepted => {
                //         console.log('User accepted notifications: ' + accepted);
                //     });
            });
        });
    }

    setupPermissions() {
        try {
            if (this.platform.is('android')) {
                this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.CAMERA)
                    .then(result => console.log('Has permission?', result.hasPermission))
                    .catch(() => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.CAMERA));
                this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO)
                    .then(result => console.log('Has permission?', result.hasPermission))
                    .catch(() => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.RECORD_AUDIO));
                this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION)
                    .then(result => console.log('Has permission?', result.hasPermission))
                    .catch(() => this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION));
                    this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT).then(
                        result => {
                          if(result.hasPermission){
                            //Do nothing and proceed permission exists already
                          }else{
                            //Request for all the permissions in the array
                            this.androidPermissions.requestPermissions(
                              [
                                this.androidPermissions.PERMISSION.BLUETOOTH, 
                                this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
                                this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
                                this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
                                this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                             ])
                          }
                        },
                        err => this.androidPermissions.requestPermissions(
                          [
                            this.androidPermissions.PERMISSION.BLUETOOTH, 
                            this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
                            this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
                            this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
                            this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
                         ])
                      );
            }
        } catch (e) {
            console.error('Error in setupPermission: ', e);
        }
    }

    async checkVersion() {
        if (this.platform.is('cordova')) {
            this.userService.getAppVersion().subscribe(async (cloudVersion) => {
                const localAppVersion = await this.appVersion.getVersionCode();
                if (this.platform.is('android')) {
                    const androidCloudVersion: Version = cloudVersion.filter((x) => { return x.platform === 'android' })[0];
                    if (androidCloudVersion && localAppVersion < androidCloudVersion.version) {
                        if (androidCloudVersion.enforceUpdate) {
                            this.enforcedUpdateAlert(androidCloudVersion.store_url);
                        } else {
                            this.regularUpdateAlert(androidCloudVersion.store_url);
                        }
                    }
                }
                if (this.platform.is('ios')) {
                    const iosCloudVersion: Version = cloudVersion.filter((x) => { return x.platform === 'ios' })[0];
                    if (iosCloudVersion && localAppVersion < iosCloudVersion.version) {
                        if (iosCloudVersion.enforceUpdate) {
                            this.enforcedUpdateAlert(iosCloudVersion.store_url);
                        } else {
                            this.regularUpdateAlert(iosCloudVersion.store_url);
                        }
                    }
                }
            });
        }
    }

    async enforcedUpdateAlert(link: string) {
        const alert = await this.alertController.create({
            header: this.translate.instant('alert'),
            message: this.translate.instant('install_new_version'),
            buttons: [{
                text: 'OK',
                handler: () => {
                    window.open(link, '_blank');
                }
            }]
        });
        await alert.present();
    }

    async regularUpdateAlert(link: string) {
        const alert = await this.alertController.create({
            header: this.translate.instant('confirm'),
            message: this.translate.instant('available_version'),
            buttons: [
                {
                    text: this.translate.instant('cancel'),
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => { }
                }, {
                    text: 'Okay',
                    handler: () => {
                        window.open(link, '_blank');
                    }
                }
            ]
        });
        await alert.present();
    }
}
