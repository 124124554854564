import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertController, Platform } from '@ionic/angular';
import { ToastController } from '@ionic/angular';
import { Socket, SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { Doctor } from '../../../models/doctor/doctor';
import { BASE_URL } from '../../../../environments/environment';
import { RepositoryHelper } from '../../../utils/repository/repository-helper';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { VideochatService, OnLineUser } from '../../../repository/videochat/videochat.service';
import { SOCKETIO_URL } from '../../../../environments/environment';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
const config: SocketIoConfig = { url: SOCKETIO_URL, options: {} };
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IncomingcalldialogPage } from '../../../pages/videochat/incomingcalldialog/incomingcalldialog.page';
import { OpentokService, OTSession } from '../OpenTok/opentok.service';
import { UserService } from '../../user/user.service';
import { BackgroundcheckService } from '../../backgroundcheck/backgroundcheck.service';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { TranslateService } from '@ngx-translate/core';
import { VideoExercise } from '../../../models/protocols/exercise';
import { IncomingExerciseDialogComponent } from 'src/app/components/incoming-exercise-dialog/incoming-exercise-dialog.component';
import { ChatService } from '../../chat/chat.service';
import { ConferenceRoom, PatientSocketConferenceRoom } from 'src/app/models/room/conference-room';
import { ConferenceRoomOpentokPatientService } from '../conference-room/opentok-patient.service';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SocketIoModule.forRoot(config),
    ]
})

export class SignalingServiceModule {

    interval: any;
    audioLoaded = false;
    newChatMessage: BehaviorSubject<void> = new BehaviorSubject(null);

    // tslint:disable-next-line:max-line-length
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public alertController: AlertController,
        private socket: Socket,
        private toastCtrl: ToastController,
        private http: HttpClient,
        private videochatService: VideochatService,
        public dialog: MatDialog,
        private opentokService: OpentokService,
        private userService: UserService,
        private nativeAudio: NativeAudio,
        private platform: Platform,
        private background: BackgroundcheckService,
        private localNotifications: LocalNotifications,
        private translateService: TranslateService,
        private chatService: ChatService,
        private conferenceRoomOpentokPatientService: ConferenceRoomOpentokPatientService,
    ) {
        console.log('SignalingServiceModule created');
        this.platform.ready().then(() => {
            this.nativeAudio.preloadComplex('ringtone', '/src/assets/audio/ringtone_minimal.ogg', 1, 1, 0)
                .then(result => { })
                .catch(err => console.log(err));
        });
    }

    currentUser = '';
    observeID: string = null;
    userObserver: Observer<{ id: string, online: boolean }> = null;

    private dialogRef: any = null;

    startWS(userID: string) {
        if (this.socket.ioSocket.connected) {
            this.socket.disconnect();
        }
        console.log('STARTING WS SERVICE');
        this.interval = setInterval(() => {
            if (!this.socket.ioSocket.connected) {
                this.socket.connect();
            }
        }, 5000);

        this.socket.on('connect', (client) => {
            if (this.userService.user._id.toString() === userID) {
                this.socket.emit('set-name', userID);
                console.log('SOCKET CONNECTED');
                // setInterval(() => this.notifyOnLine(this.socket, userID), 10000);
            }
        });

        this.socket.on('reconnect', (client) => {
            this.socket.emit('set-name', userID);
            console.log('SOCKET RE-CONNECTED');
        });

        this.currentUser = userID;

        this.socket.fromEvent('users-status').subscribe(data => {
            this.userNotification(data);
        });

        this.socket.fromEvent('incoming-call').subscribe(async data => {
            if (this.background.getBackgroundStatus()) {
                const title = await this.translateService.get('videochat.incomingcall.title').toPromise();
                const text = await this.translateService.get('videochat.incomingcall.cta').toPromise();
                const isAndroid = this.platform.is('android');
                const isMobile = this.platform.is('android') || this.platform.is('ios');
                if (isMobile) {
                    this.localNotifications.schedule({
                        id: 10,
                        title,
                        text,
                        autoClear: true,
                        vibrate: true,
                        priority: 2,
                        lockscreen: true,
                        sound: isAndroid ? 'assets/audio/ringtone_minimal.mp3' : 'assets/audio/ringtone_minimal.caf',
                        timeoutAfter: 30000,
                        foreground: true,
                        badge: 1,
                        launch: true,
                        silent: false,
                    });
                }
            }
            await this.incomingCall(data);
        });

        this.socket.fromEvent('hangup-call').subscribe(async data => {
            const isMobile = this.platform.is('android') || this.platform.is('ios');
            if (isMobile) {
                await this.localNotifications.clear(10);
            }
            await this.hangUpCallEventHandler(data);
        });

        this.socket.fromEvent('start-exercise')
            .subscribe(async (data: { to: string, exercise: VideoExercise }) => {
                console.log('EXERCISE', data);
                if (this.background.getBackgroundStatus()) {
                    const title = await this.translateService.get('videochat.incomingExercise.title').toPromise();
                    const text = await this.translateService.get('videochat.incomingExercise.cta').toPromise();
                    const isAndroid = this.platform.is('android');
                    this.localNotifications.schedule({
                        id: 10,
                        title,
                        text,
                        autoClear: true,
                        vibrate: true,
                        priority: 2,
                        lockscreen: true,
                        sound: isAndroid ? 'assets/audio/ringtone_minimal.mp3' : 'assets/audio/ringtone_minimal.caf',
                        timeoutAfter: 30000,
                        foreground: true,
                        badge: 1,
                        launch: true,
                        silent: false,
                    });
                }

                if (this.dialogRef) {
                    return;
                }

                if (this.audioLoaded) {
                    await this.nativeAudio.loop('videocall_ringaudio');
                }
                this.dialogRef = this.dialog.open(IncomingExerciseDialogComponent, {
                    data: data.exercise,
                })
                    .afterClosed()
                    .subscribe(e => {
                        this.dialogRef = null;
                        if (this.audioLoaded) {
                            this.nativeAudio.stop('videocall_ringaudio');
                        }
                    });
            });

        this.socket.fromEvent('send-message')
            .subscribe(async (data: { from: string, to: string }) => {
                await this.handleChatMessage();
            });

        this.socket.fromEvent('incoming-conference-call')
            .subscribe(async (data: { doctorName: string, to: string, room: PatientSocketConferenceRoom }) => {
                await this.handleConferenceRoomLiveInvitation(data.room, data.doctorName);
            });

        this.socket.fromEvent('incoming-conference-call-exercise')
            .subscribe((data: VideoExercise) => {
                this.conferenceRoomOpentokPatientService.handleConferenceRoomExercise(data);
            });
    }

    /*
   Send start exercise to patient
   */
    sendStartExercise(user: string, exercise: VideoExercise) {
        const data = { to: user, exercise };
        this.socket.emit('start-exercise', data);
    }

    /*
    Hang up call event handler
    */
    hangUpCallEventHandler(data) {
        this.opentokService.hangUP();
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    hangUpCallMessage(data) {
        this.socket.emit('hangup-call', data);
    }

    sendhangUpCall() {
        this.hangUpCallMessage({ from: this.opentokService.oTSession.from, to: this.opentokService.oTSession.to });
    }

    /*
    Incoming call. Show modal window
     */
    async incomingCall(calldata) {
        if (this.audioLoaded) {
            await this.nativeAudio.loop('videocall_ringaudio');
            console.log('play ringtone');
        }

        console.log('incomingCall' + calldata);

        if (!this.dialogRef) {
            this.dialogRef = this.dialog.open(IncomingcalldialogPage, {
                data: { from: calldata.userName, answer: false, video: false }
            });

            this.dialogRef.afterClosed().subscribe(result => {
                if (this.audioLoaded) {
                    this.nativeAudio.stop('videocall_ringaudio');
                }
                this.dialogRef = null;
                if (result.answer) {
                    this.videochatService.jointSession(this.currentUser, calldata.sessionID).subscribe(sesion => {

                        const oTSession: OTSession = {
                            from: calldata.to,
                            to: calldata.from,
                            callerName: calldata.userName,
                            sessionID: sesion.sessionID,
                            tokenID: sesion.tokenID,
                        };
                        this.opentokService.startConference(oTSession);

                    });
                } else {
                    // hang up
                    this.hangUpCallMessage({ from: calldata.to, to: calldata.from });
                }
            });
        }

    }

    /*
    notify to subscriber chane status for client
     */
    userNotification(data) {
        const user = data.user;
        if (data.event === 'left') {
            if (this.observeID === user) {
                if (!!this.userObserver) {
                    this.userObserver.next({ id: this.observeID, online: false });
                }
            }
            // this.showToast('User left: ' + user);
        } else {
            if (this.observeID === user) {
                if (!!this.userObserver) {
                    this.userObserver.next({ id: this.observeID, online: true });
                }
            }
            // this.showToast('User joined: ' + user);
        }
    }

    /*
    disconnect web socket
     */
    disconnectWS() {
        if (this.interval != null) {
            clearInterval(this.interval);
        }

        this.socket.disconnect();

    }

    // return user on line state
    subscribeUserOnLine(userID: string) {
        // creo observable
        return new Observable<{ id: string, online: boolean }>((observer) => {

            this.userObserver = observer;
            this.observeID = userID;

            // richiedo stato iniziale
            this.videochatService.getOnLineUsers(this.currentUser, userID).subscribe((x) => {
                console.log(x);
                if (!!x && x.length === 1) {
                    observer.next({ id: this.observeID, online: x[0].online });
                }
            });
        });
    }

    unsubscribeUserOnline() {
        this.userObserver = null;
        this.observeID = null;
    }


    sendMessage(message) {
        console.log('sending msg:' + message);
        this.socket.emit('message', { text: message });

    }

    async showToast(msg) {
        const toast = await this.toastCtrl.create({
            message: msg,
            position: 'top',
            duration: 2000
        });

        await toast.present();
    }

    private async handleChatMessage(): Promise<void> {
        this.newChatMessage.next();
        await this.chatService.updateUnreadMessagesCount();
        const title = await this.translateService.get('chat.incomingMessage.title').toPromise();
        const text = await this.translateService.get('chat.incomingMessage.cta').toPromise();
        if (!this.background.getBackgroundStatus()) {
            return;
        }
        this.localNotifications.schedule({
            id: 10,
            title,
            text,
            autoClear: true,
            vibrate: true,
            priority: 2,
            lockscreen: true,
            timeoutAfter: 30000,
            foreground: true,
            badge: 1,
            launch: true,
            silent: false,
        });
    }

    private async handleConferenceRoomLiveInvitation(data: PatientSocketConferenceRoom, doctorName: string): Promise<void> {
        if (this.audioLoaded) {
            await this.nativeAudio.loop('videocall_ringaudio');
        }
        
        const from = this.translateService
            .instant('conference.incomingcall')
            .replace('DOCTOR_NAME', doctorName);

        this.dialog.open(IncomingcalldialogPage, { data: { from, answer: false } })
            .afterClosed()
            .subscribe((result) => {
                if (this.audioLoaded) {
                    this.nativeAudio.stop('videocall_ringaudio');
                }
                this.dialogRef = null;
                if (!result || !result.answer) {
                    return;
                }
                this.router.navigate(['patient', 'conference-room'], { state: { prescription: { room: data } } });
            });
    }
}


