import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BASE_URL } from '../../../environments/environment';
import { RepositoryHelper } from '../../utils/repository/repository-helper';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VideochatService {

  constructor(private httpClient: HttpClient) {
  }

  // Get on line user state
  // callinguser: user asking for state
  // checkuser: user to check
  // if checkuser == null state of all connecte users to callinguser will reported
  public getOnLineUsers(callinguser: string, checkuser: string): Observable<OnLineUser[]> {
    try {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.get<OnLineUser[]>(BASE_URL + RepositoryHelper.ONLINE_USER + '?me=' + callinguser + '&user=' + checkuser, { headers: RepositoryHelper.getHeader() })
        .pipe(map(res => {
          // tslint:disable-next-line:no-string-literal
          return res['data'].map((result) => new OnLineUser(result));
        }));
    } catch (e) {
      console.log('Error catched in SingalingService.getOnLineUsers: ', e);
      return;
    }
  }


  public callUser(meID: string, userID: string): Observable<any> {
    try {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.post(BASE_URL + RepositoryHelper.CALL_USER, { callingUserID: meID, calledUserID: userID }, { headers: RepositoryHelper.getHeader() });
    } catch (e) {
      return null;
    }
  }

  public jointSession(userID: string, sessionID: string): Observable<any> {
    try {
      // tslint:disable-next-line:max-line-length
      return this.httpClient.post(BASE_URL + RepositoryHelper.JOIN_SESSION, { userID: userID, sessionID: sessionID }, { headers: RepositoryHelper.getHeader() });
    } catch (e) {
      return null;
    }
  }

}

export class OnLineUser {
  userID: string;
  online: boolean;
  lastconnection: Date;

  constructor(values: object = {}) {
    Object.assign(this, values);
  }

}
