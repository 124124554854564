import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { VideoExercise } from 'src/app/models/protocols/exercise';
import { OPENTOKAPIKEY } from 'src/environments/environment';
declare const OT: any; // referring to window['OT'] = OpenTok JS SDK

@Injectable({
    providedIn: 'root'
})
export class ConferenceRoomOpentokPatientService {
    private isAlreadyCalling: boolean;
    private opentokSession: any;
    private publisher: any;
    private connections: Set<Connection> = new Set<Connection>();
    private streamConnectionOptions = {
        insertMode: 'append',
        width: '100%',
        height: '100%'
    };
    private connected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private connectedToDoctor: boolean;

    private isAudioStreamActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    private isVideoStreamActive$: BehaviorSubject<boolean> = new BehaviorSubject(true);

    private liveExercise: BehaviorSubject<VideoExercise> = new BehaviorSubject(null);
    liveExercise$: Observable<VideoExercise> = this.liveExercise.asObservable();

    disconnect(): void {
        try {
            this.opentokSession.disconnect();
            if (this?.opentokSession?.destroy) {
                this.opentokSession.destroy();
            }
            this.isAlreadyCalling = false;
            this.connected$.next(false);
            OT.sessions.destroy();
        } catch (e) {
            console.error('Error in disconnecting from session: ', e?.message || e);
        } finally {
            this.connections.clear();
        }
    }

    getConnectionStatus(): Observable<boolean> {
        return this.connected$.asObservable();
    }

    getIsAudioStreamActive(): Observable<boolean> {
        return this.isAudioStreamActive$.asObservable();
    }

    getIsVideoStreamActive(): Observable<boolean> {
        return this.isVideoStreamActive$.asObservable();
    }

    toggleAudioStreaming(): void {
        const actualSetting = this.isAudioStreamActive$.value;
        this.publisher.publishAudio(!actualSetting);
        this.isAudioStreamActive$.next(!actualSetting);
    }

    toggleVideoStreaming(): void {
        const actualSetting = this.isVideoStreamActive$.value;
        this.publisher.publishVideo(!actualSetting);
        this.isVideoStreamActive$.next(!actualSetting);
    }

    startConference(sessionID: string, tokenID: string, publisherDivId: string, subscriberDivId: string): void {
        if (this.isAlreadyCalling) { return; }

        this.opentokSession = OT.initSession(OPENTOKAPIKEY, sessionID);

        this.opentokSession.connect(tokenID, (error: any) => this.handleConnectionError(error));

        this.opentokSession.on({
            connectionCreated: (evt: any) => this.handleConnectionCreated(evt),
            streamDestroyed: (evt: any) => this.handleStreamDestroyed(evt),
            streamCreated: (evt: any) => this.handleStreamCreated(evt, subscriberDivId),
            sessionConnected: (evt: any) => this.handleSessionConnected(evt, publisherDivId),
        });
    }

    handleConferenceRoomExercise(exercise: VideoExercise): void {
        if (!this.isAlreadyCalling) {
            return;
        }
        this.liveExercise.next(exercise);
    }

    markLiveExerciseAsCompleted(): void {
        this.liveExercise.next(null);
    }

    triggerViewUpdate(): void {
        if (OT?.updateViews) {
            OT.updateViews();
        }
        // tslint:disable-next-line:no-console
        console.debug('UpdateViews method not found!');
    }

    private handleStreamCreated(evt: any, subscriberDivId: string): void {
        const connection = [...this.connections].find(e => e.id === evt.stream.connection.id);
        if (!connection) {
            console.error('No connection found!');
            return;
        }

        // If incoming connection is not a doctor
        if (connection.type !== 70) {
            console.log('Ignoring incoming connection because i\'m a patient and connection it\'s not coming from a doctor!');
            return;
        }
        if (this.connectedToDoctor) { return; }
        this.opentokSession.subscribe(evt.stream, subscriberDivId, this.streamConnectionOptions);
        // Mobile only
        this.triggerViewUpdate();
        this.connectedToDoctor = true;
    }

    private handleStreamDestroyed(evt: any): void {
        this.opentokSession.unsubscribe(evt.stream);
        const connection = [...this.connections].find(e => e.id === evt.stream.connection.id);
        this.connections.delete(connection);
        console.log(`Stream ${evt.stream.name} ended because ${evt.reason}`);
        this.connectedToDoctor = false;
    }

    private handleSessionConnected(evt: any, publisherDivId: string): void {
        this.publisher = OT.initPublisher(publisherDivId, this.streamConnectionOptions);
        this.opentokSession.publish(this.publisher);
        this.isAlreadyCalling = true;
        this.connected$.next(true);
    }

    private handleConnectionCreated(evt: any): void {
        const [userid, type] = [evt.connection.data.split(';')[0], +evt.connection.data.split(';')[1]];
        const connection = [...this.connections].find(e => e.userid === userid);
        if (connection) {
            return;
        }
        this.connections.add({
            id: evt.connection.id,
            type,
            userid
        });
    }

    private handleConnectionError(error): void {
        if (!error) {
            return;
        }
        console.error(`There was an error connecting to the session ${error?.message || JSON.stringify(error)}`);
        this.connected$.next(false);
    }
}

interface Connection {
    id: string;
    userid: string;
    type: number;
}
