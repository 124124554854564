import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { FirebaseX } from "@ionic-native/firebase-x/ngx";
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links/ngx';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatIconModule } from '@angular/material/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ReactiveFormsModule } from '@angular/forms';
import { UserService } from './services/user/user.service';
import { DynamicLinkService } from './services/dynamic-links/dynamic-link.service';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { AuthService } from './services/auth/auth.service';
import { ErrorsHandler } from './utils/errors/errors-handler';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SignalingServiceModule } from './services/videochat/signaling-service/signaling-service.module';
import { SuperTabs, SuperTabsModule } from '@ionic-super-tabs/angular';
import {
    CalendarModule,
    DateAdapter,
    CalendarNativeDateFormatter,
    DateFormatterParams,
    CalendarDateFormatter,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { registerLocaleData } from '@angular/common';
import localeIT from '@angular/common/locales/it';
import loacaleEN from '@angular/common/locales/en';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { LanguageInterceptor } from './utils/language-interceptor.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { TranslateConfigService } from './services/language/translate-config.service';
import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
import { VideocallModalDesktopComponent } from './components/videocall-modal-desktop/videocall-modal-desktop.component';
import { VideocallModalMobileComponent } from './components/videocall-modal-mobile/videocall-modal-mobile.component';
import { MatSelectModule } from '@angular/material/select';
import { Storage } from '@ionic/storage-angular';
import { BluetoothLE } from '@ionic-native/bluetooth-le/ngx';
import { AlertController } from '@ionic/angular';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { UnauthorizedInterceptor } from './utils/unauthorized-interceptor.service';
import { AppVersion } from "@awesome-cordova-plugins/app-version/ngx";
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { bluetoothServiceProvider } from './services/sensors/providers/bluetooth-service-base.provider';
import { AppNameInterceptor } from './utils/app-name-interceptor.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';



registerLocaleData(localeIT, loacaleEN);

export function LanguageLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');

}

class CustomDateFormatter extends CalendarNativeDateFormatter {
    public dayViewHour({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat(locale, { hour: 'numeric', minute: 'numeric' }).format(date);
    }

    public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
        return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
    }
}

export let AppInjector: Injector;


@NgModule({
    declarations: [AppComponent, VideocallModalDesktopComponent, VideocallModalMobileComponent],
    imports: [
        BrowserModule,
        MatIconModule,
        MatDatepickerModule,
        MatNativeDateModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatSnackBarModule,
        IonicModule.forRoot({
            backButtonText: '',
        }),
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxMaterialTimepickerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: LanguageLoader,
                deps: [HttpClient],
            },
            defaultLanguage: 'en'
        }),
        MatProgressSpinnerModule,
        SignalingServiceModule,
        SuperTabsModule.forRoot(),
        CalendarModule.forRoot(
            {
                provide: DateAdapter,
                useFactory: adapterFactory,
            },
            {
                dateFormatter: {
                    provide: CalendarDateFormatter,
                    useClass: CustomDateFormatter,
                },
            }
        ),
        MatSelectModule,
    ],
    providers: [
        ErrorsHandler,
        StatusBar,
        SplashScreen,
        NativeAudio,
        SuperTabs,
        FirebaseAnalytics,
        FirebaseX,
        FirebaseDynamicLinks,
        Deeplinks,
        AndroidPermissions,
        UserService,
        MatBottomSheet,
        AuthService,
        MatSnackBarModule,
        DynamicLinkService,
        MatDatepickerModule,
        DatePipe,
        ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        LocalNotifications,
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AppNameInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
        { provide: LOCALE_ID, deps: [TranslateConfigService], useFactory: (svc: TranslateConfigService) => svc.getDefaultLanguage() },
        TextToSpeech,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        BluetoothLE,

        Storage,
        AlertController,
        LocationAccuracy,
        AlertController,
        AppVersion,
        bluetoothServiceProvider,
        Clipboard
    ],
    exports: [MatSelectModule],
    bootstrap: [AppComponent],

})

export class AppModule {
    constructor(private injector: Injector) {
        AppInjector = this.injector;
    }
}

