import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuardService } from './services/guards/admin/admin-guard.service';
import { StructureGuardService } from './services/guards/structure/structure-guard.service';
import { DoctorGuardService } from './services/guards/doctor/doctor-guard.service';
import { PatientGuardService } from './services/guards/patient/patient-guard.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'admin',
    canActivate: [AdminGuardService],
    canActivateChild: [AdminGuardService],
    loadChildren: () =>
      import('./pages/admin/admin-routing.module').then(m => m.AdminRoutingModule),
  },
  {
    path: 'structure',
    canActivate: [StructureGuardService],
    canActivateChild: [StructureGuardService],
    loadChildren: () =>
      import('./pages/structure/structure-routing.module').then(m => m.StructureRoutingModule),
  },
  {
    path: 'doctor',
    canActivate: [DoctorGuardService],
    canActivateChild: [DoctorGuardService],
    loadChildren: () =>
      import('./pages/doctor/doctor-routing.module').then(m => m.DoctorRoutingModule),
  },
  {
    path: 'patient',
    canActivate: [PatientGuardService],
    canActivateChild: [PatientGuardService],
    loadChildren: () =>
      import('./pages/patient/patient-routing.module').then(m => m.PatientRoutingModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/public/login/login.module').then(m => m.LoginPageModule),
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/public/register/register.module').then(m => m.RegisterPageModule),
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/public/invite/invite.module').then(m => m.InvitePageModule),
  },
  {
    path: 'incomingcalldialog',
    loadChildren: () =>
      import('./pages/videochat/incomingcalldialog/incomingcalldialog.module').then(
        m => m.IncomingcalldialogPageModule,
      ),
  },
  {
    path: 'invite',
    loadChildren: () => import('./pages/public/invite/invite.module').then(m => m.InvitePageModule),
  },
  {
    path: 'disclaimer',
    loadChildren: () =>
      import('./pages/public/disclaimer/disclaimer.module').then(m => m.DisclaimerPageModule),
  },
  {
    path: 'password-update',
    loadChildren: () =>
      import('./pages/password-update/password-update.module').then(
        m => m.PasswordUpdatePageModule,
      ),
  },
  {
    path: 'changepassword',
    loadChildren: () =>
      import('./pages/public/changepassword/changepassword.module').then(
        m => m.ChangepasswordPageModule,
      ),
  },
  {
    path: 'forgotpassword',
    loadChildren: () =>
      import('./pages/public/forgotpassword/forgotpassword.module').then(
        m => m.ForgotpasswordPageModule,
      ),
  },
  {
    path: 'resend-invitation',
    loadChildren: () =>
      import('./pages/public/resend-invitation/resend-invitation.module').then(
        m => m.ResendInvitationPageModule,
      ),
  },
  {
    path: 'date-chooser',
    loadChildren: () =>
      import('./pages/doctor/exercises/date-chooser/date-chooser.module').then(
        m => m.DateChooserPageModule,
      ),
  },
  {
    path: 'structure-detail',
    loadChildren: () => import('./pages/admin/structure-detail/structure-detail.module').then( m => m.StructureDetailPageModule)
  },
  {
    path: 'exercise-execution-dynamic',
    loadChildren: () => import('./pages/public/exercise-execution-dynamic/exercise-execution-dynamic.module').then( m => m.ExerciseExecutionDynamicPageModule)
  },
  {
    path: 'exercise-next-page',
    loadChildren: () => import('./pages/public/exercise-next-page/exercise-next-page.module').then( m => m.ExerciseNextPagePageModule)
  },
  {
    path: 'exercise-next-page',
    loadChildren: () => import('./pages/public/exercise-next-page/exercise-next-page.module').then( m => m.ExerciseNextPagePageModule)
  },
 
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  // imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule, DragDropModule],
})
export class AppRoutingModule {}
