import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';
import { OpentokService } from 'src/app/services/videochat/OpenTok/opentok.service';
import { SignalingServiceModule } from 'src/app/services/videochat/signaling-service/signaling-service.module';
import { User } from '../../models/user/user';

@Component({
  selector: 'app-videocall-modal-desktop',
  templateUrl: './videocall-modal-desktop.component.html',
  styleUrls: ['./videocall-modal-desktop.component.scss'],
})
export class VideocallModalDesktopComponent implements OnInit {

  hideVideoCtrl = true;
  subscriberHeight = '300px';
  subscriberWidth = '200px';
  subscriberStream = false;
  publisherWidth = '300px';
  publisherHeight = '200px';
  publisherView = true;

  viewVideoIcon = false;
  dragging = false;

  fullscreen = false;
  videoRatio = 1.333;

  dragPosition = { x: 0, y: 0 };


  constructor(
    private platform: Platform,
    private userService: UserService,
    private signal: SignalingServiceModule,
    private opentokService: OpentokService,
  ) {
    this.fullscreen = false;
    this.updateVideoSize();
  }

  ngOnInit(): void {
    console.log('VideocallModalDesktopComponent');
    this.opentokService.subscribeStartConference().subscribe((started) => {
      this.hideVideoCtrl = !started;
      this.subscriberStream = false;
      this.fullscreen = true;
      this.updateVideoSize();
    });

    this.opentokService.subscribeStreamCreated().subscribe((stream) => {
      this.videoRatio = stream.width / stream.height;
      this.subscriberStream = true;
      this.updateVideoSize();
      console.log('Remote stream subscribed');
    });
  }

  fullSizeVideo() {
    this.fullscreen = true;
    this.updateVideoSize();
  }

  reduceSizeVideo() {
    this.fullscreen = false;
    this.updateVideoSize();
  }

  maximizeVideo() {
    if (!this.dragging) {
      this.fullscreen = true;
      this.updateVideoSize();
    }
  }

  updateVideoSize() {
    let width = this.platform.width() / 2;
    let height = this.platform.height() / 2;

    if (this.fullscreen) {
      width = this.platform.width();
      height = this.platform.height();
    }

    if (User.getUserRoleString(this.userService.user.role) === 'patient') {
      if (!this.fullscreen) {
        width = 50;
        height = 48;
        this.viewVideoIcon = true;
      } else {
        this.viewVideoIcon = false;
      }
    }

    if (width < height) {
      height = width / this.videoRatio;
    } else {
      width = height * this.videoRatio;
    }


    this.subscriberWidth = width + 'px';
    this.subscriberHeight = height + 'px';

    if (!this.subscriberStream) {
      this.publisherWidth = this.subscriberWidth;
      this.publisherHeight = this.subscriberHeight;
    } else {
      if (this.fullscreen) {
        this.publisherView = true;
        this.publisherWidth = (width / 3).toString() + 'px';
        this.publisherHeight = (height / 3).toString() + 'px';
      } else {
        this.publisherView = !this.subscriberStream;
        this.publisherWidth = this.subscriberWidth;
        this.publisherHeight = this.subscriberHeight;
      }
    }

    this.dragPosition = { x: 0, y: 0 };
    console.log('publisher' + this.publisherWidth + ' ' + this.subscriberHeight);
    console.log('subscriber' + this.subscriberWidth + ' ' + this.subscriberHeight);
  }

  hangUp() {
    this.opentokService.hangUP();
    this.subscriberStream = false;
    this.signal.sendhangUpCall();
  }

  startDrag() {
    this.dragging = true;
  }

  endDrag($event) {
    setTimeout(() => {
      this.dragging = false;
    }, 100);

  }

}
