import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.headers.has('language')) {
            return next.handle(req);
        }
        try {
            const serviceInj = this.injector.get(TranslateService);
            const language = serviceInj.getBrowserLang();

            if (req.url.includes('https://vimeo.com')) {
                return next.handle(req);
            }

            const languageRequest = req.clone({
                setHeaders: { language }
            });
            return next.handle(languageRequest);
        } catch (error) {
            return next.handle(req);
        }

    }
}
