import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


export interface DialogData {
  from: string;
  video: boolean;
  answer: boolean;
}

@Component({
  selector: 'app-incomingcalldialog',
  templateUrl: './incomingcalldialog.page.html',
  styleUrls: ['./incomingcalldialog.page.scss'],
})
export class IncomingcalldialogPage implements OnInit {

  result: DialogData;
  callFrom: string;
  constructor(
      public dialogRef: MatDialogRef<IncomingcalldialogPage>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    this.result = data;
    this.callFrom = data.from;
  }

  acceptClick(): void {
    this.result.answer = true;
    this.dialogRef.close(this.result);
    }

  refuseClick(): void {
    this.result.answer = false;
    this.dialogRef.close(this.result);
  }

  ngOnInit(): void {

  }


}
