
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../../models/user/user';
import { BASE_URL, COGNITO_CLIENT_ID, environment } from '../../../environments/environment';
import { RepositoryHelper } from '../../utils/repository/repository-helper';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { UiConstants } from '../../constants/ui/ui-constants';
import { UserService } from '../user/user.service';
import { NavController, Platform } from '@ionic/angular';
import { ErrorsHandler } from '../../utils/errors/errors-handler';
import { SignalingServiceModule } from '../videochat/signaling-service/signaling-service.module';
import { OpentokService } from '../videochat/OpenTok/opentok.service';
import { BackendLoginResult } from './LoginResult';
import { tap } from 'rxjs/operators';
import {UiHelperService} from '../../utils/ui/ui-helper.service';
import OneSignal from 'onesignal-cordova-plugin';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private httpClient: HttpClient,
        private userService: UserService,
        private navController: NavController,
        private errorHandler: ErrorsHandler,
        private signal: SignalingServiceModule,
        private opentokService: OpentokService,
        private uiHelper: UiHelperService,
        private platform: Platform,
        private translateService: TranslateService,
        private androidPermissions: AndroidPermissions
    ) {
        this.subjectUserAuthenticated = new BehaviorSubject<boolean>(false);
        this.userisAuthenticatedObservable = this.subjectUserAuthenticated.asObservable();
        this.subjectUserAuthenticated.next(this.isUserAuthenticated());
    }
    public subjectUserAuthenticated: BehaviorSubject<boolean>;
    public userisAuthenticatedObservable: Observable<boolean>;

    public static getRefreshedCredentialsFromCognito(httpClient: HttpClient): Observable<CognitoLoginData> {
        const tokenExpiration = +localStorage.getItem(UiConstants.TOKEN_EXPIRATION);
        if (Date.now() < tokenExpiration) {
            return of({
                AuthenticationResult: {
                    AccessToken: localStorage.getItem(UiConstants.TOKEN_STORAGE_KEY),
                    ExpiresIn: +localStorage.getItem(UiConstants.TOKEN_EXPIRATION),
                    IdToken: localStorage.getItem(UiConstants.ID_TOKEN_STORAGE_KEY),
                    RefreshToken: localStorage.getItem(UiConstants.REFRESH_TOKEN_STORAGE_KEY),
                    TokenType: 'Bearer'
                },
                ChallengeParameters: {}
            });
        }

        const refreshToken = localStorage.getItem(UiConstants.REFRESH_TOKEN_STORAGE_KEY);

        if (!refreshToken) {
            throw new Error('Can\'t refresh credentials without a refresh token!');
        }

        const postData = {
            AuthParameters: {
                REFRESH_TOKEN: refreshToken
            },
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            ClientId: COGNITO_CLIENT_ID
        };

        return httpClient.post<CognitoLoginData>(
            RepositoryHelper.LOGINCOGNITO,
            postData,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
                }
            })
            .pipe(
                tap(authResult => {
                    localStorage.setItem(UiConstants.TOKEN_STORAGE_KEY, authResult.AuthenticationResult.AccessToken);
                    localStorage.setItem(UiConstants.ID_TOKEN_STORAGE_KEY, authResult.AuthenticationResult.IdToken);
                    localStorage.setItem(UiConstants.TOKEN_EXPIRATION, authResult.AuthenticationResult.ExpiresIn.toString());
                })
            );
    }

    public login(email: string, password: string) {
        try {
            console.log(`LOGIN ON SERVER: ${BASE_URL}`);

            this.loginOnCognito({ username: email, password })
                .subscribe(async (loginData) => {
                    if (!loginData) {
                        console.error('Failed to login!');
                        return;
                    }
                    console.log(loginData)


                    localStorage.setItem(UiConstants.TOKEN_STORAGE_KEY, loginData.AuthenticationResult.AccessToken);
                    localStorage.setItem(UiConstants.ID_TOKEN_STORAGE_KEY, loginData.AuthenticationResult.IdToken);
                    localStorage.setItem(UiConstants.REFRESH_TOKEN_STORAGE_KEY, loginData.AuthenticationResult.RefreshToken);
                    localStorage.setItem(UiConstants.TOKEN_EXPIRATION, loginData.AuthenticationResult.ExpiresIn.toString());

                    const { user } = await this.httpClient
                        .post<BackendLoginResult>(BASE_URL + RepositoryHelper.LOGIN, {}, { headers: RepositoryHelper.getHeader() })
                        .toPromise();
                        this.navController.navigateRoot([User.getUserRoleString(user.role)]);

                    localStorage.setItem(UiConstants.USER_ROLE_STORAGE_KEY, user.role.toString());
                    localStorage.setItem(UiConstants.USER_ID_STORAGE_KEY, user._id.toString());
                    
                    // tslint:disable-next-line:no-string-literal
                    console.log(this.platform.is('mobileweb'));
                    if (this.platform.is('cordova')) {
                        // tslint:disable-next-line:no-string-literal
                        OneSignal.setExternalUserId(user._id);
                    }
                    // TODO: Remove cast and fix User class
                    this.userService.subjectUser.next(user as unknown as User);
                    this.subjectUserAuthenticated.next(true);

                    this.androidPermissions.requestPermissions([
                        this.androidPermissions.PERMISSION.RECORD_AUDIO,
                        this.androidPermissions.PERMISSION.CAMERA,
                        this.androidPermissions.PERMISSION.GET_ACCOUNTS,
                        this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION,
                        this.androidPermissions.PERMISSION.BLUETOOTH,
                        this.androidPermissions.PERMISSION.BLUETOOTH_ADMIN,
                        this.androidPermissions.PERMISSION.BLUETOOTH_CONNECT,
                        this.androidPermissions.PERMISSION.BLUETOOTH_SCAN,
                    ]).then((d) => {
                        console.log('requestPermissions', d);
                    }).catch((e) => {
                        console.log('requestPermissions error', e);
                    });

                }, error => {
                    this.uiHelper.showToast(this.translateService.instant('http_errors.incorrect_fields'));

                    // this.errorHandler.handleError(error);
                    this.subjectUserAuthenticated.next(false);
                    this.userService.subjectUser.next(null);
                });
        } catch (e) {
            console.log('Error catched in AuthService.login');
        }
    }

    public isUserAuthenticated(): boolean {
        const token = localStorage.getItem(UiConstants.TOKEN_STORAGE_KEY);
        return token != null;
    }

    public logout() {
        localStorage.removeItem(UiConstants.TOKEN_STORAGE_KEY);
        localStorage.removeItem(UiConstants.TOKEN_EXPIRATION);
        localStorage.removeItem(UiConstants.ID_TOKEN_STORAGE_KEY);
        localStorage.removeItem(UiConstants.REFRESH_TOKEN_STORAGE_KEY);
        localStorage.removeItem(UiConstants.USER_ROLE_STORAGE_KEY);
        localStorage.removeItem(UiConstants.USER_ID_STORAGE_KEY);

        this.subjectUserAuthenticated.next(false);
        this.userService.subjectUser.next(null);

        this.signal.disconnectWS();
        this.opentokService.hangUP();
        console.log('Disconnecte from signaling service and opentok');
        this.navController.navigateRoot(['login']);
    }

    private loginOnCognito({ username, password }: { username: string, password: string }): Observable<CognitoLoginData> {
        const postData = {
            AuthParameters: {
                USERNAME: username,
                PASSWORD: password
            },
            AuthFlow: 'USER_PASSWORD_AUTH',
            ClientId: COGNITO_CLIENT_ID
        };

        return this.httpClient.post<CognitoLoginData>(
            RepositoryHelper.LOGINCOGNITO,
            postData,
            {
                headers: {
                    'Content-Type': 'application/x-amz-json-1.1',
                    'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
                }
            });
    }
}

interface CognitoLoginData {
    AuthenticationResult: AuthenticationResult;
    ChallengeParameters: unknown;
}

interface AuthenticationResult {
    AccessToken: string;
    ExpiresIn: number;
    IdToken: string;
    RefreshToken: string;
    TokenType: string;
}
