import { BluetoothLE } from "@ionic-native/bluetooth-le/ngx";
import { Platform, ToastController } from "@ionic/angular";
import { BluetoothServiceBase } from "../services/bluetooth_service_base";
import { SensorBluetoothServiceApp } from "../services/app/sensor-bluetooth-app.service";
import { SensorBluetoothServiceWeb } from "../services/web/sensor-bluetooth-web.service";
import { TranslateService } from "@ngx-translate/core";


export let bluetoothServiceBaseFactory = (
    platform: Platform, bt: BluetoothLE) => {
    if (platform.is('android') || platform.is('ios')){
        return new SensorBluetoothServiceApp(platform, bt);
    } else {
        return new SensorBluetoothServiceWeb(platform);
    }
};

export let bluetoothServiceProvider = {
    provide: BluetoothServiceBase,
    useFactory: bluetoothServiceBaseFactory,
    deps: [
        Platform,
        BluetoothLE
    ]
};

