import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError,from } from 'rxjs';
import { UiConstants } from '../constants/ui/ui-constants';
import { AuthService } from '../services/auth/auth.service';
import { catchError, flatMap } from 'rxjs/operators';
import { AlertController } from '@ionic/angular'; //
import { tap, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    private isAlertShown = false;

    constructor(private injector: Injector,private alertController: AlertController) { 

        
    }

    
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    try {
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status !== 401) {
            return throwError(err);
          }

          const errorBody = err.error;
          if (errorBody && errorBody.code === 'not_active_user' && errorBody.fieldName === 'isActive') {
            console.log('intercepting 401 with not_active_user error');

            if (!this.isAlertShown) {
              this.isAlertShown = true;
              return this.showAlert().pipe(
                switchMap(() => {
                  this.isAlertShown = false;
                  return throwError(err);
                })
              );
            }

            // Puoi anche gestire ulteriormente l'errore qui se necessario

            // Ritorna l'errore per propagarlo agli osservatori sottostanti
            return throwError(err);
          }

          // Se non è un errore "not_active_user" con fieldName "isActive", continua con la gestione dell'errore di default
          return throwError(err);
        })
      );
    } catch (error) {
      return next.handle(req);
    }
  }

  showAlert(): Observable<void> {
    return new Observable<void>(observer => {
      // const translatedMessage =  this.translateService.get('login_error').toPromise();

      this.alertController.create({

        header: 'Attenzione',
        message: `Il tuo account è disattivato`,
        buttons: [{
          text: 'OK',
          handler: () => {
            observer.next();
            observer.complete();
          }
        }],
        backdropDismiss: false, // L'utente non può chiudere l'alert facendo clic al di fuori di esso
      }).then(alert => {
        alert.present();
      });
    });
  }
    
}