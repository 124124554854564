import { Injectable, OnDestroy } from '@angular/core';
import { BluetoothLE, CurrConnectionStatus, DeviceInfo } from '@ionic-native/bluetooth-le/ngx';
import { Platform, ToastController } from '@ionic/angular';
import { BehaviorSubject, Observable, of, Subscription, throwError } from 'rxjs';
import { tap, take, map, catchError, filter } from 'rxjs/operators';
import { BluetoothServiceBase } from '../bluetooth_service_base';
import { BLEConnectionStatus, BLEDevice } from '../../interfaces/ble-device.interface';
import { BTData } from '../../interfaces/bt-data.interface';
import { BTMeasurement } from 'src/app/components/dynamic-exercise-player/dynamic-exercise-player.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SensorBluetoothServiceApp extends BluetoothServiceBase {


    selectDevice(id): void {
        let device: BLEDevice = this.getDeviceFromId(id);
        device.selected = true;
        this.updateDevice(device, true);

    }
    unselectDevice(id): void {
        let device: BLEDevice = this.getDeviceFromId(id);
        device.selected = false;
        this.updateDevice(device, true);
    }

    private bt: BluetoothLE;
    private scanningDevices$: Subscription;

    bleDevices: BLEDevice[] = [];
    isScanning: boolean = false;

    constructor(platform: Platform, bt: BluetoothLE) {
        super(platform);
        this.bt = bt;
        this._checkIfIsScanning();

    }

    private async _checkIfIsScanning(): Promise<void> {
        this.isScanning = (await this.bt.isScanning()).isScanning;
    }

    async disconnectSpecificSensor(deviceAddress: string): Promise<void> {
        try {
            console.log('[SBS] Unsubscribing from notify listening');
            await this.bt.unsubscribe({
                address: deviceAddress,
                service: this.measuramentServiceUUID.toString(),
                characteristic: this.shortLongPayloadcUuid.toString()
            });

            console.log('[SBS] Writing on characteristic in order to stop measuring');
            await this.bt.write({
                address: deviceAddress,
                service: this.measuramentServiceUUID.toString(),
                characteristic: this.controlCharacteristicUuid.toString(),
                // [Type, Action, Payload]
                // [measurement, start/stop, payloadMode (5 is orientation)]
                value: this.bt.bytesToEncodedString(new Uint8Array([1, 0, 5])),
                type: 'noresponse'
            });

            console.log('[SBS] Disconnecting BT from sensor');
            await this.bt.disconnect({ address: deviceAddress });
            await this.bt.close({ address: deviceAddress });
        } catch (e) {
            console.warn(`[SBS] Error encountered in disconnectToSensor(${deviceAddress})`, e?.message || e);
        }
    }


    async initService(): Promise<void> {
        console.log('[SBS] Started InitService');
        await this.platform.ready();
        console.log('[SBS] Platform ready');
        const init = await this.bt.initialize({ request: true }).toPromise();
        this.initSavedDevices();
        //this.bleDevices = [...this.getSavedDevices()];
        console.log('[SBS] Initialize finished with status:', init);
    }



    startScan(): void {
    this.isScanning = true;
    console.warn(`[SBS] startScan`);
    try {
        this.scanningDevices$ = this.bt
            .startScan({})
            .pipe(
                filter((scan) => scan.name === 'Xsens DOT' || scan.name === 'Movella DOT')
            )
            .subscribe(foundDevice => {
                let isDeviceAlreadySelected: boolean = this.getSelectedDevices().findIndex((e) => e.id.toLowerCase() === foundDevice.address.toLowerCase()) > -1;
                if (!isDeviceAlreadySelected) {
                    let tmpDevice: BLEDevice = {
                        name: foundDevice.name,
                        id: foundDevice.address,
                        observables: {},
                        selected: false,
                        status: BLEConnectionStatus.disconnected
                    };
                    this.addDevice(tmpDevice, true);
                }
            });
    } catch (e) {
        this.isScanning = false;
        console.error(`[SBS] startScan has caught an error`, e);
    }
}

    async connect(deviceAddress: string): Promise<boolean> {


        let device: BLEDevice = this.getDeviceFromId(deviceAddress);
        if (device != null) {
            device.status = BLEConnectionStatus.connecting;
            this.updateDevice(device, true);
        }

        console.warn(`[SBS] connectToSensor started`);
        let currConnStatus: void | CurrConnectionStatus = undefined;
        try {
            currConnStatus = await this.bt.isConnected({ address: deviceAddress }).catch((e) => console.log("[SBS] isConnected error"));
        } catch (e) {
            console.warn(`[SBS] catched in isConnected ${e}`);
        }
        if (!currConnStatus || !currConnStatus.isConnected) {
            // const deviceInfoDisconnetted: void | DeviceInfo = await this.bt.disconnect({ address: deviceAddress }).catch(e => console.log("[SBS] disconnect cathed error"));
            // console.warn(`[SBS] deviceInfoDisconnetted -> ${JSON.stringify(deviceInfoDisconnetted)}`);
            // console.warn(`[SBS] disconnected`);
            // console.warn(`[SBS] close`);
            // await this.bt.close({ address: deviceAddress }).catch(e => console.log("[SBS] close cathed error"));;
            // if (device != null) {
            //     device.status = BLEConnectionStatus.disconnected;
            //     this.updateDevice(device, true);
            // }

            if (device != null) {
                device.status = BLEConnectionStatus.connecting;
                this.updateDevice(device, true);
            }
            console.warn(`[SBS] connect started`);

            const connectionResult = await this.bt.connect({
                address: deviceAddress,
                autoConnect: true,
            }).pipe(
                tap(data => console.warn(`[SBS] Device ${data.address} is in status ${data.status}`)),
                take(1),
                map(data => data.status !== 'connected' ? throwError('Device not connected') : true)
            ).toPromise();

            if (!connectionResult) {
                if (device != null) {
                    device.status = BLEConnectionStatus.disconnected;
                    this.updateDevice(device, true);
                }
                throw new Error('Sensor not connected');
            }
        }

        await this.bt.discover({ address: deviceAddress }).catch((e) => console.log("[SBS] discover error"));;

        if (device != null) {
            device.status = BLEConnectionStatus.connected;
            this.updateDevice(device, true);
        }

        await this.bt.write({
            address: deviceAddress,
            service: this.measuramentServiceUUID.toString(),
            characteristic: this.controlCharacteristicUuid.toString(),
            // [Type, Action, Payload]
            // [measurement, start/stop, payloadMode (5 is orientation)]
            value: this.bt.bytesToEncodedString(new Uint8Array([1, 1, 5])),
            type: 'noresponse'
        });

        let characteristicObserver: Observable<BTData> = this.bt
            .subscribe({
                address: deviceAddress,
                service: this.measuramentServiceUUID.toString(),
                characteristic: this.shortLongPayloadcUuid.toString(),
            })
            .pipe(
                tap(subscribedRes => {
                    if (subscribedRes.status !== 'subscribedResult') {
                        // tslint:disable-next-line:max-line-length

                        console.log(`[SBS] Subscribed successfully to ${this.shortLongPayloadcUuid}, status is: ${subscribedRes.status}`);
                    }
                }),
                map(subscribed => {
                    if (subscribed.status !== 'subscribedResult') {
                        return null;
                    }
                    return {
                        address: deviceAddress,
                        value: this.bt.encodedStringToBytes(subscribed.value),
                    };
                }),
                filter(event => event != null),
                catchError(err => {
                    console.error(`[SBS] Error encountered in connectToSensor(${deviceAddress}) | subscribe`, err?.message || err);
                    return of(null);
                })
            );

        if (characteristicObserver != null) {
            if (device != null) {
                device.observables.controlSensorValue = new BehaviorSubject<Uint8Array>(new Uint8Array());
                this.updateDevice(device, false);
            }
            characteristicObserver.subscribe(data => {
                device.observables.controlSensorValue.next(data.value);
            });
            this.presentToast('deviceSettings.connectedSucessfully', 'light');
            return true;
        }
        this.presentToast('deviceSettings.errorConnectingSensor', 'warn');
        return false;
    }
    dispose(): void {
        throw new Error('Method not implemented.');
    }
    async stopScan(): Promise<void> {
        await this.bt.stopScan();
        this.isScanning = false
    }

}