import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {NavController} from '@ionic/angular';
import {UserRoles} from '../../../constants/user/user-roles';
import {UiConstants} from '../../../constants/ui/ui-constants';
import {User} from '../../../models/user/user';
import {DynamicLinkService} from '../../dynamic-links/dynamic-link.service';

@Injectable({
    providedIn: 'root'
})
export class DoctorGuardService implements CanActivate, CanActivateChild {
    private _userRole: number;
    private _token: string;

    constructor(private navController: NavController, private dynamicLinkService: DynamicLinkService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        this._userRole = Number(localStorage.getItem(UiConstants.USER_ROLE_STORAGE_KEY));

        if (this._userRole != null && !this.dynamicLinkService.dynamicLinkOnOpen) {
            if (this._userRole !== UserRoles.DOCTOR) {
                this.navController.navigateForward(['login']);
            }
        }
        return this.dynamicLinkService.dynamicLinkOnOpen ? true : this._userRole === UserRoles.DOCTOR;
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        this._token = (localStorage.getItem(UiConstants.USER_ROLE_STORAGE_KEY));

        if (this._token == null && !this.dynamicLinkService.dynamicLinkOnOpen) {
            this.navController.navigateRoot(['login']);
        }

        return this.dynamicLinkService.dynamicLinkOnOpen ? true : this._token != null;

    }
}
