import { Injectable } from '@angular/core';
import { OPENTOKAPIKEY } from '../../../../environments/environment';
import { Observable, Observer } from 'rxjs';
declare var OT: any;

export interface OTSession {
    from: string;
    to: string;
    callerName: string;
    sessionID: boolean;
    tokenID: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class OpentokService {

    oTSession: OTSession;
    session: any;
    publisher: any;
    subscriber: any;
    token: string;
    startCallObserver: Observer<boolean>;
    subscriberStreamCreated: Observer<{ width: number, height: number }>;
    calling = false;

    constructor() {
    }

    startConference(oTSession: OTSession) {

        if (this.calling) {
            return;
        }
        try {
            this.startCallObserver.next(true);
            this.calling = true;
        } catch (e) {
            console.log('startConference - startCallObserver.next');
            console.log(e);
        }

        this.oTSession = oTSession;
        this.session = OT.initSession(OPENTOKAPIKEY, this.oTSession.sessionID);
        const publisherOptions = {
            insertMode: 'append',
            width: '100%',
            height: '100%'
        };

        this.session.connect(this.oTSession.tokenID, (error: any) => {
            if (error) {
                console.log(`There was an error connecting to the session ${error}`);
            }
        });

        this.publisher = OT.initPublisher('publisher', publisherOptions);

        this.session.on({
            streamCreated: (event: any) => {
                const subscriberOptions = {
                    insertMode: 'append',
                    width: '100%',
                    height: '100%'
                };

                const size = event.stream.videoDimensions;
                this.subscriberStreamCreated.next({ width: size.width, height: size.height });
                this.subscriber = this.session.subscribe(event.stream, 'subscriber', subscriberOptions);
            },
            streamDestroyed: (event: any) => {
                this.session.unsubscribe(event.stream);
                this.startCallObserver.next(false);
                console.log(`Stream ${event.stream.name} ended because ${event.reason}`);
            },
            sessionConnected: (event: any) => {
                this.session.publish(this.publisher); // The publish() method starts publishing an audio-video stream to the session.
            }

        });


    }

    subscribeStartConference() {
        // creo observable
        console.log('subscribeStartConference creo observable');
        return new Observable<boolean>((observer) => {
            this.startCallObserver = observer;
            this.startCallObserver.next(false);
        });
    }

    subscribeStreamCreated() {
        return new Observable<{ width: number, height: number }>((observer) => {
            this.subscriberStreamCreated = observer;
        });
    }

    hangUP() {
        if (this.startCallObserver) {
            this.startCallObserver.next(false);
        }
        if (this.session) {
            this.session.disconnect();
        }
        if (this.publisher) {
            // this.publisher.destroy();
        }
        if (this.subscriber) {
            // this.subscriber.destroy();
        }
        this.calling = false;

    }


}
