import { UiConstants } from '../../constants/ui/ui-constants';

export class RepositoryHelper {
  // AUTHENTICATION
  static LOGIN = '/auth/login';
  static LOGINCOGNITO = 'https://cognito-idp.eu-west-1.amazonaws.com/';
  static LOGOUT = '/auth/logout';
  static PASSWORD_REGEX = '/passwordregex';
  // INVITES
  static INVITE_STRUCTURE = '/invites/structures';
  static INVITE = '/invites';
  static INVITE_DOCTOR = '/invites/doctors';
  static INVITE_PATIENT = '/invites/patients';
  static INVITE_PATIENT_ANONYMOUS = '/users/patients/anonymous';
  static DELETE_PATIENT = '/users/{id}/dissociation';
  static SUGGEST_PATIENT = '/invites/patients';
  static STRUCTURES_NOTIFICATIONS = '/invites/structures';
  static PATIENTS_NOTIFICATIONS = '/invites/patients';
  static DOCTORS_NOTIFICATIONS = '/invites/doctors';
  static STRUCTURE_INVITES_SENT = '/invites/structures?action=sent';
  static STRUCTURE_INVITES_REQUEST = '/invites/structures?action=received';
  static PATIENT_INVITES_REQUESTS = '/invites/doctors?action=received';
  static PATIENT_INVITES_SENT = '/invites/doctors?action=sent';
  static DOCTOR_INVITES_REQUESTS = '/invites/patients?action=received';
  static DOCTOR_INVITES_SENT = '/invites/patients?action=sent';
  static CONFIRM_REQUEST = '/invites';
  static VALIDATE_INVITE_TOKEN = '/invites/validate';
  // PERMISSIONS
  static CHANGE_PERMISSIONS = '/users/me/permissions';
  static ASK_PERMISSIONS = '/invites/patients/update-permissions';
  // REGISTER
  static REGISTER_PATIENT = '/users/patients';
  static REGISTER_DOCTOR = '/users/doctors';
  static REGISTER_STRUCTURE = '/users/structures';
  // USER
  static USER = '/users/me';
  static USER_UPDATE_PASSWORD = '/users/me/password';
  static USERS = '/users'; // This is can be used to return specific user passing the ID of user (e.g /users/{id})
  static USER_UPDATE = '/users/update' 
  // DETECTIONS
  static DETECTIONS = '/measurements';
  // DOCTORS LIST
  static DOCTORS_LIST = '/users/doctors';
  // DOCTORS LIST
  static PATIENTS_LIST = '/users/patients';
  // STRUCTURE LIST
  static STRUCTURE_LIST = '/users/structures';
  static NUM_MAX_PATIENTS_ACTIVE = '/users/structures/nummaxactivepatients' //get/set max number patients for the structure
  static NUM_PATIENTS_ACTIVE = '/users/structures/numactivepatients' //get actual number of active patients for the structure
  static PATIENT_ACTIVE =  '/users/structures/setactivepatient' //set patient active
  static STRUCTURE_GROUPS = '/users/groups'
  // VIDEO CHAT
  static ONLINE_USER = '/videochat/onlineusers';
  static CALL_USER = '/videochat/calluser';
  static JOIN_SESSION = '/videochat/joinsession';
  // PRESCRIPTIONS
  static PRESCRIPTIONS = '/prescriptions';
  static ADHOC_PRESCRIPTIONS = '/measurements/exercises';
  static ALLPRESCRIPTIONS = '/prescriptions/patient';
  static CONFIRM_MEDICATION = '/measurements/prescription/medicine';
  static SAVE_EXERCISE_RESULT = '/measurements/prescription/exerciseResult';
  static SAVE_ADHOC_EXERCISES = '/measurements/exercises';
  static SAVE_ADHOC_EXERCISES_BY_DOCTOR = '/prescriptions/exercises';
  static PRESCRIPTIONS_SUMMARY = '/prescriptions/summary';
  static UPDATE_PRESCRIPTION = '/prescriptions/update';
  static OPTIONS_PRESCRIPTION = '/prescriptions/options';
  static CLINIC_SUMMARY = '/prescriptions/clinicsummary';
  static CLINIC_SUMMARY_GZIP = '/prescriptions/clinicsummarygzip';
  static ADMIN_SUMMARY_GZIP = '/prescriptions/adminsummarygzip';


  // DISCLAIMER
  static DISCLAIMER = '/disclaimer';
  // PROTOCOLS
  static PROTOCOLS = '/protocols';
  static PROTOCOLS_DOCTOR = '/protocols/doctor';
  static PROTOCOL_DETAILS = '/protocol/details';
  static EXERCISES = '/exercises';
  static EXERCISES_FILTERS = '/exercises/filters';
  // PASSWORD
  static SEND_PASSWORD_CHANGE_EMAIL = '/users/me/resetpassword';
  static SEND_FORGOT_PASSWORD_CHANGE_EMAIL = '/password/change/sendEmail';
  static CHANGE_PASSWORD = '/password/reset';
  // CHAT
  static CREATE_MESSAGE = '/chat/message';
  static USER_NOTIFICATION = '/chat/notification';
  static MESSAGES = '/chat/messages';
  static DELETE_MESSAGE = '/chat/message';
  // ROOMS
  static GET_ROOMS = '/conferenceroom/list';
  static CREATE_ROOM = '/conferenceroom/create';
  static EDIT_ROOM = '/conferenceroom/update';
  static DELETE_ROOM = '/conferenceroom/delete';
  static DELETE_USER = '/users';
  static DELETE_SPECIFIC_USER = '/specificuser';
  static ADD_PATIENT_TO_CONFERENCE_ROOM = '/conferenceroom/adduser';
  static START_EXERCISE_IN_CONFERENCE_ROOM = '/conferenceroom/startExercise';
  // DYNAMIC EXERCISES
  static EXERCISE_OPTIONS_DEFINITION = '/exercises/options_definition';
  // APP VERSION
  static GET_APP_VERSION = '/app/version';
  // ASSOCIATION
  static ASSOCIATE_DOCTOR = '/users/{id}/association';
  // Register Structure
  static REGISTER_ANONYMOUS_STRUCTURE = '/users/admin/structures';
  static REGISTER_ANONYMOUS_DOCTOR = '/users/admin/doctors';
  static getHeader() {
    const token = localStorage.getItem(UiConstants.TOKEN_STORAGE_KEY);
    let reqHeader = {};
    if (token != null) {
      reqHeader = {
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + localStorage.getItem(UiConstants.TOKEN_STORAGE_KEY),
      };
    } else {
      reqHeader = {
        'Content-Type': 'application/json',
      };
    }

    return reqHeader;
  }
}
