import { IUser } from '../../interfaces/user-interface';

export class User implements IUser {
	_id: string;
	createdAt: string;
	email: string;
	firstName: string;
	lastName: string;
	role: number;
	name: string;
	birthDate: string;
	isAnonymous: boolean;
	mobilePhone: string;
	username: string;
	specialization: string;

	constructor(values: Object = {}) {
		Object.assign(this, values);
	}

	public static getUserRoleString(userRole: number): string {
		switch (userRole) {
			case 100:
				return 'admin'; // CRISPY USER BUT IS SAME AS ADMIN
				break;
			case 90:
				return 'admin';
				break;
			case 80:
				return 'structure';
				break;
			case 70:
				return 'doctor';
				break;
			case 60:
				return 'patient';
				break;
			default:
				return '';
		}
	}
}
