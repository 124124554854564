import { Injectable, NgZone } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { DynamicLinksPaths } from '../../constants/dynamic-links/dynamic-links-paths';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks/ngx';
import * as JWT from 'jwt-decode';
import { NavigationExtras, Route, Router } from '@angular/router';
import { User } from '../../models/user/user';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InviteRequest } from '../../models/invite-request/invite-request';
import { Cordova, cordova, cordovaInstance } from '@ionic-native/core';

@Injectable({
    providedIn: 'root'
})
export class DynamicLinkService {
    public dynamicLinkOnOpen: boolean = false;

    public subjectOnDynamiclinkOpen: BehaviorSubject<boolean>;
    public onDynamicLinkOpenObservable: Observable<boolean>;

    public _subjectInviteRequestPending: BehaviorSubject<string>;
    public inviteRequestPendingObservable: Observable<string>;


    constructor(private deepLinks: Deeplinks, private navController: NavController, private router: Router, private ngZone: NgZone, private platform: Platform) {
        this.subjectOnDynamiclinkOpen = new BehaviorSubject<boolean>(false);
        this.onDynamicLinkOpenObservable = this.subjectOnDynamiclinkOpen.asObservable();

        this._subjectInviteRequestPending = new BehaviorSubject<string>(null);
        this.inviteRequestPendingObservable = this._subjectInviteRequestPending.asObservable();
    }

    init() {
        console.log('DynamicLinkService init()');
        this.deepLinks.routeWithNavController(this.navController, {
            'register': DynamicLinksPaths.REGISTER_ACTION,
            'invite': DynamicLinksPaths.INVITE_ACTION,
            'changepassword': DynamicLinksPaths.CHANGE_PASSWORD
        }).subscribe(match => {
            // match.$route - the route we matched, which is the matched entry from the arguments to route()
            // match.$args - the args passed in the link
            // match.$link - the full link data
            console.log('Successfully matched link', JSON.stringify(match.$link));
            console.log('Successfully matched args', match.$args);
            console.log('Successfully matched link', match.$route);
            this.redirectUser(match.$link.url);
        }, nomatch => {
            // nomatch.$link - the full link data
            console.error('Got a deeplink that didn\'t match', nomatch);
        });
    }

    decodeToken(token: string): any {
        return JWT(token);
    }

    getAction(url: string): string {
        let action: string;
        const parsedAction = url.substr(url.lastIndexOf('/') + 1);
        const splittedAction = parsedAction.split('?');
        action = splittedAction[0];
        return action;
    }

    private parseUrl(url: string): Map<string, string> {
        const urlParameters: Map<string, string> = new Map();
        if (url != null) {
            if (url.indexOf('?') > 0) {
                const splitURL = url.split('?');
                const splitParams = splitURL[splitURL.length - 1].split('&');
                for (let i = 0; i < splitParams.length; i++) {
                    const singleURLParam = splitParams[i].split('=');
                    const key: string = singleURLParam[0];
                    const value: any = singleURLParam[1];
                    urlParameters.set(key, value);
                }
            }
        }
        return urlParameters;
    }

    public doRegisterAction(token: string) {
        let navigationExtras: NavigationExtras = { queryParams: { token: token } };
        console.log('dynamic link doRegisterAction', JSON.stringify(navigationExtras));
        this.ngZone.run(() => {
            console.log('dynamic doRegisterAction link ngZone');
            this.navController.navigateRoot(['register'], navigationExtras);
        });

    }

    public doInviteAction(inviteId: string) {
        console.log('doInviteAction');
        console.log(inviteId);
        let navigationExtras: NavigationExtras = { queryParams: { invite: inviteId } };
        this.ngZone.run(() => {
            this.navController.navigateRoot(['invite'], navigationExtras);
        });

    }

    public doChangePasswordAction(token: string) {
        let navigationExtras: NavigationExtras = { queryParams: { token: token } };
        console.log('dynamic link doChangePasswordAction', navigationExtras);
        this.ngZone.run(() => {
            console.log('dynamic doChangePasswordAction link ngZone');
            this.navController.navigateRoot(['changepassword'], navigationExtras);
        });
    }

    redirectUser(link) {
        console.log('DynamicLinkService redirectUser', link);
        try {
            console.log('dynamic link catched', link);

            if (link) {
                const url = new URL(link);
                console.log('url', JSON.stringify(url));
                // const action = this.getAction(url);
                // const params = this.parseUrl(url);
                // console.log('dynamic link action', action);
                // console.log('dynamic link params', params);
                const actionUrlLink = url.searchParams.get('link');
                const action = this.getAction(actionUrlLink);
                const innerURL = new URL(actionUrlLink);
                console.log('dynamic link action', action);
                switch (action) {
                    case DynamicLinksPaths.REGISTER_ACTION:
                        console.log("url.searchParams.get('token')", innerURL.searchParams.get('token'));
                        this.doRegisterAction(innerURL.searchParams.get('token'));
                        break;
                    case DynamicLinksPaths.INVITE_ACTION:
                        console.log("url.searchParams.get('invite')", innerURL.searchParams.get('invite'));
                        this.doInviteAction(innerURL.searchParams.get('invite'));
                        break;
                    case DynamicLinksPaths.CHANGE_PASSWORD:
                        console.log("url.searchParams.get('token')", innerURL.searchParams.get('token'));
                        this.doChangePasswordAction(innerURL.searchParams.get('token'));
                        break;
                }
            } else {
                console.log('deep link null');
            }
        } catch (error) {
            console.log("Error occurred on deep link catch", error);

        }
    }
}

