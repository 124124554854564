import { Injectable } from "@angular/core";
import { Platform, ToastButton, ToastController } from "@ionic/angular";
import { XDotCharacteristicsHelper } from "src/app/utils/bluetooth/xdot-characteristics-helper";
import { BLEConnectionStatus, BLEDevice } from "../interfaces/ble-device.interface";
import { TranslateService } from "@ngx-translate/core";
import { AppInjector } from "src/app/app.module";

@Injectable()
export abstract class BluetoothServiceBase {


    // Characteristics and services
    measuramentServiceUUID: String = XDotCharacteristicsHelper.services.measuramentService.uuid;
    controlCharacteristicUuid: String = XDotCharacteristicsHelper.services.measuramentService.characteristics.controlUuid;
    shortLongPayloadcUuid: String = XDotCharacteristicsHelper.services.measuramentService.characteristics.shortPayloadLengthUuid;

    // Methods
    abstract initService(): Promise<void>;
    abstract startScan(): void;
    abstract connect(address: string): Promise<boolean>;
    abstract dispose(): void;
    abstract stopScan(): Promise<void>;
    abstract disconnectSpecificSensor(address: string): Promise<void>;
    abstract selectDevice(id: String): void;
    abstract unselectDevice(id: String): void;

    // Var
    public abstract bleDevices: BLEDevice[];
    public abstract isScanning: boolean;

    constructor(protected platform: Platform) { };


    getSavedDevices(): BLEDevice[] {
        let savedDevices: BLEDevice[] = JSON.parse(localStorage.getItem('chosenDevices'));
        return savedDevices ?? []

    }

    private _saveLocalDevices() {
        localStorage.setItem('chosenDevices', JSON.stringify(this.bleDevices));
    }

    public addDevice(newDevice: BLEDevice, updateLocalStorage?: boolean) {
        if (!newDevice) {
            return;
        }
        let indexOfDevice = this._getConnectedDeviceIndexFromId(newDevice.id);
        if (indexOfDevice > -1) {
            this.bleDevices[indexOfDevice] = newDevice;
        } else {
            this.bleDevices.push(newDevice);
        }
        if (updateLocalStorage === true) {
            this._saveLocalDevices();
        }

    }

    async disconnectFromAll(): Promise<void> {
        console.log('[SBS] disconnectFromAll');
        this.bleDevices.forEach((device) => {
            console.log('[SBS] ', device.id);
            this.disconnectSpecificSensor(device.id.toString());
        });
    }

    public updateDevice(updatedDevice: BLEDevice, updateLocalStorage?: boolean) {
        try {
            if (!updatedDevice || !this.bleDevices) {
                return;
            }
            let indexOfDevice = this._getConnectedDeviceIndexFromId(updatedDevice.id);
            if (indexOfDevice > -1) {
                this.bleDevices[indexOfDevice] = updatedDevice;
                if (updateLocalStorage === true) {
                    this._saveLocalDevices();
                }
            }
        } catch (e) {
            console.error('[BSB] updateDevice has catched an error', e)
        }

    }

    public removeDevice(id: String, updateLocalStorage?: boolean) {
        try {
            if (!id || !this.bleDevices) {
                return;
            }
            let indexOfDevice = this._getConnectedDeviceIndexFromId(id);
            if (indexOfDevice > -1) {
                if (this.bleDevices[indexOfDevice].observables) this.bleDevices[indexOfDevice].observables.controlSensorValue.unsubscribe();
                this.bleDevices.splice(indexOfDevice, 1);
                if (updateLocalStorage === true) {
                    this._saveLocalDevices();
                }
            }
        } catch (e) {
            console.error('[BSB] removeDevice has catched an error', e)
        }
    }

    public removeDevices() {
        try {
            if (!this.bleDevices) {
                return;
            }
            this.bleDevices.forEach(device => {
                if (device.observables) device.observables.controlSensorValue?.unsubscribe();
            });
            this.bleDevices = [];
            this._saveLocalDevices();
        } catch (e) {
            console.error('[BSB] removeDevice has catched an error', e)
        }
    }

    public getDeviceFromId(id: String): BLEDevice {
        if (!id || !this.bleDevices) {
            return null;
        }
        return this.bleDevices[this._getConnectedDeviceIndexFromId(id)];
    }


    public isDeviceAlreadyConnected(id: String): boolean {
        try {
            if (!id) {
                return false;
            }
            let indexOfDevice: number = this._getConnectedDeviceIndexFromId(id)
            if (this._getConnectedDeviceIndexFromId(id) > -1) {
                return this.bleDevices[indexOfDevice].status === BLEConnectionStatus.connected
            }
            return false;
        } catch (e) {
            console.error('[BSB] isDeviceAlreadyConnected has catched an error', e)
        }

    }

    initSavedDevices() {
        this.bleDevices.forEach((device) => {
            device.status = BLEConnectionStatus.disconnected;
            this.updateDevice(device, true);
        })
        this.bleDevices.push(...this.getSavedDevices());
    }

    private _getConnectedDeviceIndexFromId(id: String): number {
        if (!id || !this.bleDevices) {
            return null;
        }
        return this.bleDevices.findIndex((e) => e.id.toLowerCase() === id.toLowerCase());
    }

    public getSelectedDevices(): BLEDevice[] {
        if (this.bleDevices == undefined || this.bleDevices == null || this.bleDevices.length == 0) {
            this.initSavedDevices();
        }

        return this.bleDevices.filter((device) => device.selected === true);
    }

    public getUnselectedDevices(): BLEDevice[] {
        if (this.bleDevices == undefined || this.bleDevices == null || this.bleDevices.length == 0) {
            this.initSavedDevices();
        }

        return this.bleDevices.filter((device) => !device.selected);
    }


    async presentToast(text: string,color: 'light' | 'warn') {
        
        const translate: TranslateService = AppInjector.get(TranslateService);
        const toastController: ToastController = AppInjector.get(ToastController);
        
        let buttons: ToastButton[] = [];

        if(color === 'warn'){
            buttons.push({
                text: translate.instant('deviceSettings.reconnect'),
                role: 'info',
            })
        }       
        buttons.push({
            text: translate.instant('deviceSettings.cancel'),
            role: 'cancel',
        })
        const toast = toastController.create({
            message: translate.instant(text),
            duration: 1500,
            position: 'bottom',
            color,
            buttons
        });

        await (await toast).present();
    }
}