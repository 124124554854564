import { ResultsData } from "./ResultsData";

export class VideoExercise {
    // tslint:disable-next-line:variable-name
    _id: string;
    name: string;
    repetitions: number;
    speed: number;
    URLVideoInstruction: string;
    URLVideoMovement: string;
    CompensationInstructions: string;
    PrimaryInstructions: string;
    RespirationInstructions: string;
    IsPossibleToModulateDifficulty: boolean;
    IsPossibleToModulateRepetitions: boolean;
    image: string;
    URLImage?: string;
    executed: number;
    type: 'video';
    completed?: boolean;
    //for webgexercises
    totalTime?: number;
    averageScore?: number;
    resultsData?: ResultsData[];
    index?: number;
}
