<div (cdkDragStarted)="startDrag()" (cdkDragEnded)="endDrag($event)" class="videocall-modal-mobile"
  [style.height]="modalHeight" [style.width]="modalWidth" [hidden]="hideModal" cdkDragBoundary=".drag-boundary" cdkDrag
  [cdkDragFreeDragPosition]="dragPosition" visible="false" [ngClass]="{'minimize':reducedMode}">
  <div class="video-holder" [ngClass]="{'minimize': reducedMode}">
    <div id="publisher"></div>
    <div id="subscriber"></div>
  </div>
  <div *ngIf="reducedMode">
    <ion-button (click)="toggleReducedMode()" size="large" fill="clear">
      <ion-icon src="./assets/icon/icon_fullscreen-exit.svg"></ion-icon>
    </ion-button>
  </div>
  <div *ngIf="!reducedMode" class="buttons-holder">
    <ion-button (click)="toggleReducedMode()" size="large" fill="clear">
      <ion-icon src="./assets/icon/icon_fullscreen.svg"></ion-icon>
    </ion-button>
    <ion-button (click)="hangUp()" size="large" fill="clear">
      <ion-icon src="./assets/icon/icon_hangupcall.svg"></ion-icon>
    </ion-button>
  </div>
</div>