import { Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { UiHelperService } from '../ui/ui-helper.service';

@Injectable()
export class ErrorsHandler {
    constructor(
        private translate: TranslateService,
        private uiHelper: UiHelperService
    ) { }
    // For now Just handle HttpErrorResponse Type
    public handleError(error: Error | HttpErrorResponse | any) {
        const classname = error.constructor.name;
        let message: string;
        switch (classname) {
            case 'HttpErrorResponse':
                message = !navigator.onLine
                    ? this.translate.instant('http_errors.no_iternet_connection')
                    : this.translate.instant('http_errors.' + error.error.code);
                break;
        }

        // //console.log("messsage" , message)

        // this.zone.run(
        //     () => {
        //         snackBar.open(message, null, {duration: 2000, panelClass:"error"});
        //     });
        this.uiHelper.showToast(message, 'error');
    }
}
