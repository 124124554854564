import { ChatRepositoryService } from 'src/app/repository/chat/chat-repository.service';
import { Injectable } from '@angular/core';
import { ChatMessageCreate } from 'src/app/models/chat/ChatMessageCreate';
import { CreateResponse } from 'src/app/models/chat/CreateResponse';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { BasePaginateResponse } from 'src/app/models/chat/BasePaginateResponse';
import { ChatMessage } from 'src/app/models/chat/ChatMessage';
import { delay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ChatService {

    constructor(private chatRepository: ChatRepositoryService) {
        this.updateUnreadMessagesCount().then(() => { });
    }

    unreadMessages$: BehaviorSubject<{ _id: string }[]> = new BehaviorSubject([]);

    createMessage(message: ChatMessageCreate): Observable<CreateResponse> {
        return this.chatRepository.createMessage(message);
    }

    listUnreadMessages(page: number = 1): Observable<BasePaginateResponse<{ _id: string }>> {
        return this.chatRepository.listUnreadMessages(page);
    }

    getMessages(page: number = 1, recipient: string): Observable<BasePaginateResponse<ChatMessage>> {
        return this.chatRepository.getMessages(page, recipient);
    }

    deleteMessage(messageId: string): Observable<CreateResponse> {
        return this.chatRepository.deleteMessage(messageId);
    }

    async updateUnreadMessagesCount() {
        const { data } = await this.listUnreadMessages(1).toPromise();
        this.unreadMessages$.next(data);
    }

}
