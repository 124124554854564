import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserRepositoryService } from '../../repository/user/user-repository.service';
import { User } from '../../models/user/user';
import { Version } from '../../models/version/version';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	public user: User = new User();

	public subjectUser: BehaviorSubject<User>;
	public userObservable: Observable<User>;

	constructor(private userRepository: UserRepositoryService) {
		this.subjectUser = new BehaviorSubject<User>(new User());
		this.userObservable = this.subjectUser.asObservable();
	}

	async getUser(): Promise<User> {
		const user: User = await this.userRepository.getUser().toPromise();
		this.user = user;
		return user;
	}
	isActive(userId: string) {
		return this.userRepository.isUserActive(userId);
	}
	getSpecificUser(userId: string) {
		return this.userRepository.getSpecificUser(userId);
	}
	getExercisesFilters(){
		return this.userRepository.getExercisesFilters();

	}
	updateUserData(data: object): Observable<object>{
		return this.userRepository.updateUserData(data);
	}
	getStudyNames(id){
		return this.userRepository.getStructureGroups(id);
	}
	updateStructureGroups(structureId, groups){
		return this.userRepository.updateStructureGroups(structureId,groups);
	}
	updatePassword(newPassword: string): Observable<string> {
		return this.userRepository.updatePassword(newPassword);
	}

	deleteUser(): Observable<void> {
		return this.userRepository.deleteUser(this.user._id);
	}

	deleteSpecificUser({ _patientId }: { _patientId: string; }): Observable<void> {
		return this.userRepository.deleteUser(_patientId);

	}

	getAppVersion(): Observable<Version[]> {
		return this.userRepository.getVersionUpdate();
	}
	getNumMaxActivePatients(userId: string): Observable<any> {
		return this.userRepository.getNumMaxActivePatients(userId);
	}
	getNumActivePatients(userId: string): Observable<any> {
		return this.userRepository.getNumActivePatients(userId);
	}
	setNumMaxActivePatients(structureId: string, numMaxActivePatients: any) {
		return this.userRepository.setNumMaxActivePatients(structureId, numMaxActivePatients);
	}
	getNumPatients(userId: string): Observable<any> {
		return this.userRepository.getNumPatients(userId)
	}
}
