import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VideoExercise } from 'src/app/models/protocols/exercise';
import { Router } from '@angular/router';
import { Protocol } from 'src/app/models/protocols/protocol';
import { Prescription } from 'src/app/models/prescription/prescription';

@Component({
  selector: 'app-incoming-exercise-dialog',
  templateUrl: './incoming-exercise-dialog.component.html',
  styleUrls: ['./incoming-exercise-dialog.component.scss'],
})
export class IncomingExerciseDialogComponent implements OnInit {

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<IncomingExerciseDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VideoExercise
  ) { }

  ngOnInit() { }

  accept() {
    this.dialogRef.close();
    const fakeProtocol: Protocol = {
      _id: null,
      difficulty: 0,
      domain: null,
      exercises: [this.data],
      icon: null,
      name: null,
      subDomain: null
    };
    // TODOF: Implement mixed exercise, but not now!
    const fakePrescription: Prescription = {
      _id: null,
      category: null,
      datetime: null,
      executed: false,
      medicine: null,
      measurement: null,
      exercise: {
        advancement: 0,
        protocolId: null,
        protocolName: null,
        result: [{
          executed: 0,
          exerciseId: this.data._id,
          name: this.data.name,
          repetitions: this.data.repetitions
        }]
      }
    };
    this.router.navigate(['patient', 'exercise-execution'],
      {
        state: {
          exercise: this.data,
          protocol: fakeProtocol,
          prescription: fakePrescription,
        }
      });
  }

  refuse() {
    this.dialogRef.close();
  }

}
