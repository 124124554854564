import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackgroundcheckService {

  constructor() {
    document.addEventListener('pause', () => this.isAppInBackground = true, false);
    document.addEventListener('resume', () => this.isAppInBackground = false, false);
  }

  private isAppInBackground = false;

  getBackgroundStatus(): boolean {
    return this.isAppInBackground;
  }

}
