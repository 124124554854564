<div class="modal-wrapper">
    <h1 mat-dialog-title class="mat-dialog-title">{{'videochat.incomingcall.title' | translate}}</h1>
    <div mat-dialog-content class="mat-dialog-content">
        <div class="name-label">
            <ion-label color="primary">{{callFrom}}</ion-label>
        </div>
        <ion-grid>
            <ion-row class="controls-floating-bottom">
                <ion-col>
                    <ion-button [fill]="'clear'" (click)="acceptClick()">
                        <ion-icon class="ion-icon" name="call" [style.color]="'#00FF00'"></ion-icon>
                    </ion-button>
                </ion-col>
                <ion-col>
                    <ion-button [fill]="'clear'" (click)="refuseClick()">
                        <ion-icon class="ion-icon" src="./assets/icon/icon_hangupcall.svg"></ion-icon>
                    </ion-button>
                </ion-col>
            </ion-row>
        </ion-grid>
    </div>
</div>