import { BLEDeviceObservable } from "./ble-device.observable.interface";

export interface BLEDevice {

    // Name of the device
    name?: String;

    // Id of the device (Mac Address for APP)
    id: String;

    // Status of current device @see[BLEConnectionStatus]
    status: BLEConnectionStatus;

    // Observable to the charactetistics @see[BLEDeviceObservable]
    observables?: BLEDeviceObservable;

    // To use only for APP this property is only to support APP legacy mode
    selected?: boolean;

    // to maintain the connection order
    index?: number;
}

export enum BLEConnectionStatus {
    connected = 'connected',       // Device is connected
    connecting = 'connecting',     // Device is connecting
    disconnected = 'disconnected'  // Device is disconnected
}